<template>
  <DashboardLeftMenu></DashboardLeftMenu>
  <UserProfile></UserProfile>
</template>

<script>
import DashboardLeftMenu from "@/components/DashboardLeftMenu";
import UserProfile from "@/views/UserProfile";
export default {
  name: "RouteUserProfile",
  components:{
    DashboardLeftMenu,
    UserProfile
  }
}
</script>

<style scoped>

</style>