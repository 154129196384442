<template>
  <DashboardLeftMenu></DashboardLeftMenu>
  <TablePgLog></TablePgLog>
</template>

<script>
import DashboardLeftMenu from "@/components/DashboardLeftMenu";
import TablePgLog from "@/views/TablePgLog";
export default {
  name: "RoutePgLog",
  components:{
    DashboardLeftMenu,
    TablePgLog
  }
}
</script>

<style scoped>

</style>