import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Select2 from 'vue3-select2-component';
import { loadFonts } from './plugins/webfontloader'
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import VueMultiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.css';
import 'vuefinder/dist/style.css'
import VueFinder from 'vuefinder/dist/vuefinder'
loadFonts()

const pinia = createPinia();
const app = createApp(App);
    app.config.globalProperties.$myGlobalVariable = true;
    app
    .use(pinia)
    .use(router)
    .use(store)
    .use(vuetify)
    .use(VueFinder)
    .component('EasyDataTable',Vue3EasyDataTable)
    .component('VueMultiselect',VueMultiselect)
    .component('Select2', Select2)
    .mount('#app');

