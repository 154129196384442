<template>
    <v-dialog
        persistent
        v-model="payinDialog"
        width="auto"
      >
        <v-card>
            <v-card-title>
              <span class="text-h5">{{ title }}</span>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col class="text-right" v-if="canDownload"> 
                        <v-menu class="mr-3">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                color="primary"
                                v-bind="props"
                                prepend-icon="mdi-download"
                                >
                                Download
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item
                                    v-for="(item, index) in downloadItems"
                                    :key="index"
                                    :value="index"
                                    @click="f_submitdownload(item.id)"
                                    >
                                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-col>
                </v-row>
                <v-container>
                    <EasyDataTable
                      show-index
                      buttons-pagination
                      alternating
                      v-model:server-options="serverOptions"
                      :server-items-length="numberOfPages"
                      :loading="loading"
                      :headers="headers"
                      :items="list"
                      :search-field="searchField"
                      :search-value="searchValue"
                  >
                    <template #loading>
                      <img :src="imageLoading" style="width: 100px; height: 80px;"/>
                    </template>
                  </EasyDataTable>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" block @click="this.$emit('closeDialog')">Close Dialog</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
// import {formatNumber, getFormattedNumber} from "@/services/number-format"
// import VueDatePicker from '@vuepic/vue-datepicker';

export default {
    name: "ModalPayinDetail",
    props:{
        title: String,
        list: Object,
        openDialog: Boolean,
        canDownload: Boolean,
        f_submitdownload: undefined,
    },
    components: {
        // VueDatePicker
    },
    data:()=>({
        payinDialog: false,
        loading: false,
        numberOfPages : 0,
        serverOptions:{
            page: 1,
            rowsPerPage: 25,
            sortBy:'',
            sortType:'',
        },
        downloadItems: [
            { title: 'As CSV', id:'csv' },
            { title: 'As Excel', id:'excel' },
            { title: 'As PDF', id: 'pdf' }
        ],
        searchField:'refNo',
        searchValue:'',
        headers: [
            { text: "Trx Date", value: "trx_datetime" , sortable: true},
            { text: "Merchant Code", value: "partner_code" , sortable: true},
            { text: "Merchant Name", value: "partner_name" , sortable: true},
            { text: "RefNo", value: "refNo" , sortable: true},
            { text: "RC", value: "trx_status" , sortable: true},
            { text: "Status", value: "sts_desc" , sortable: true},
            { text: "SourceRefNo", value: "source_refno" , sortable: true},
            { text: "Inv. No", value: "source_invno" },
            { text: "Currency", value: "currency" },
            { text: "Channel", value: "product_name" , sortable: true},
            { text: "Gross Amount (Rp.)", value: "amount" , sortable: true},
            { text: "Fee (Rp.)", value: "feeAmount" },
            { text: "Net Amount (Rp.)", value: "netAmount" },
            { text: "Settled", value: "is_settled" },
            { text: "Settlement Days", value: "settlement_time" },
            { text: "See Detail", value: "view" , sortable: false},
        ],
    }),
    emits:['closeDialog', 'download'],
    methods: {
       
    },
    watch: {
        openDialog: function(newVal) {
            this.payinDialog = newVal;
        },
        list: function(newVal) { 
            console.log('newval', newVal)
            // if (newVal != null) {
            //     this.formData.wallet_address_trc20 = newVal.wallet_address_trc20;
            //     this.formData.withdrawal_date = newVal.withdrawal_date;
            //     this.formData.total_usdt_buy_amt = newVal.total_usdt_buy_amt;
            //     this.formData.total_buying_crypto_amt = newVal.total_buying_crypto_amt;
            //     this.formData.settlement_charge_to_merchant = newVal.settlement_charge_to_merchant;
            //     this.formData.crypto_sent_to_merchant = newVal.crypto_sent_to_merchant;
            // }
        }
    }
}
</script>