<template>
  <main class="main-content">
    <NavigationTop :pages=currentRouteName :subpages=currentRouteName></NavigationTop>
    <div class="container-fluid py-4">
      <div class="row">
        <div class="col-12">
          <div class="card mb-12">
            <div class="card-header pb-0">
              <h6>Page {{ currentRouteName }}</h6>
            </div>
            <div class="card">
              <div class="card-body p-3">
                <div class="row mb-3">
                  <div class="col-md-12">
                    <div class="alert alert-light" role="alert">
                      * Gunakan titik untuk prosentase misal 0.01 (1%)
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="input-group">
                      <span class="input-group-text bg-primary">Product Amount</span>
                      <span class="input-group-text">Rp.</span>
                      <input type="text" v-model=datatosend.amount class="form-control"
                             aria-label="Dollar amount (with dot and two decimal places)">
                    </div>
                  </div>
                  <div class="col-md-6">
                    <select class="form-select" v-model=datatosend.mode aria-label="Default select example">
                      <option selected>Pilih Jenis Transaksi</option>
                      <option value="payin">PAYIN</option>
                      <option value="payout">PAYOUT</option>
                    </select>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-md-6">
                    <div class="input-group">
                      <span class="input-group-text bg-success">Toppay Fix Fee</span>
                      <input type="text" v-model="datatosend.toppay.ffee" aria-label="First name"
                             class="form-control pl-3" placeholder="Gunakan titik untuk prosentase misal 0.01 (1%)">
                    </div>
                  </div>
                  <div class="col-md-6">
                    <select class="form-select" v-model="datatosend.toppay.fmodel" aria-label="Default select example">
                      <option selected>Pilih Tipe Fee</option>
                      <option value="F">Fixed</option>
                      <option value="P">Persentase</option>
                    </select>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-md-6">
                    <div class="input-group">
                      <span class="input-group-text bg-success">Toppay Security Fee</span>
                      <input type="text" v-model="datatosend.toppay.sfee" aria-label="First name"
                             class="form-control pl-3" placeholder="Gunakan titik untuk prosentase misal 0.01 (1%)">
                    </div>
                  </div>
                  <div class="col-md-6">
                    <select class="form-select" v-model="datatosend.toppay.smodel" aria-label="Default select example">
                      <option selected>Pilih Tipe Fee</option>
                      <option value="F">Fixed</option>
                      <option value="P">Persentase</option>
                    </select>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-md-6">
                    <div class="input-group">
                      <span class="input-group-text bg-warning">Referral Fix Fee</span>
                      <input type="text" v-model="datatosend.referral.ffee" aria-label="First name"
                             class="form-control pl-3" placeholder="Gunakan titik untuk prosentase misal 0.01 (1%)">
                    </div>
                  </div>
                  <div class="col-md-6">
                    <select class="form-select" v-model="datatosend.referral.fmodel" aria-label="Default select example">
                      <option selected>Pilih Tipe Fee</option>
                      <option value="F">Fixed</option>
                      <option value="P">Persentase</option>
                    </select>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-md-6">
                    <div class="input-group">
                      <span class="input-group-text bg-warning">Referral Secutity Fee</span>
                      <input type="text" v-model="datatosend.referral.sfee" aria-label="First name"
                             class="form-control pl-3" placeholder="Gunakan titik untuk prosentase misal 0.01 (1%)">
                    </div>
                  </div>
                  <div class="col-md-6">
                    <select class="form-select" v-model="datatosend.referral.smodel" aria-label="Default select example">
                      <option selected>Pilih Tipe Fee</option>
                      <option value="F">Fixed</option>
                      <option value="P">Persentase</option>
                    </select>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-md-6">
                    <div class="input-group">
                      <span class="input-group-text bg-blue-grey">Supplier Fix Fee</span>
                      <input type="text" v-model="datatosend.supplier.ffee" aria-label="First name"
                             class="form-control pl-3" placeholder="Gunakan titik untuk prosentase misal 0.01 (1%)">
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="input-group">
                      <select class="form-select" v-model="datatosend.supplier.fmodel"
                              aria-label="Default select example">
                        <option selected>Pilih Tipe Fee</option>
                        <option value="F">Fixed</option>
                        <option value="P">Persentase</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-md-6">
                    <div class="input-group">
                      <span class="input-group-text bg-blue-grey">Supplier Security Fee</span>
                      <input type="text" v-model="datatosend.supplier.sfee" aria-label="First name"
                             class="form-control pl-3" placeholder="Gunakan titik untuk prosentase misal 0.01 (1%)">
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="input-group">
                      <select class="form-select" v-model="datatosend.supplier.smodel"
                              aria-label="Default select example">
                        <option selected>Pilih Tipe Fee</option>
                        <option value="F">Fixed</option>
                        <option value="P">Persentase</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-md-6">
                    <div class="col-md-8 form-control mb-3 bg-blue-grey">
                      <div class="input-group mb-3">
                        <span class="input-group-text">Fix Fee Toppay</span>
                        <span class="input-group-text bg-gray-200">Rp.</span>
                        <input type="text" v-model="fixFeeTopay" class="form-control" disabled
                               aria-label="Dollar amount (with dot and two decimal places)">
                      </div>
                      <div class="input-group">
                        <span class="input-group-text">Security Fee Toppay</span>
                        <span class="input-group-text bg-gray-200">Rp.</span>
                        <input type="text" v-model="securityFeeTopay" class="form-control" disabled
                               aria-label="Dollar amount (with dot and two decimal places)">
                      </div>
                    </div>
                    <div class="col-md-8 form-control mb-3 bg-red-lighten-2">
                      <div class="input-group mb-3">
                        <span class="input-group-text">Fix Fee Referral</span>
                        <span class="input-group-text bg-gray-200">Rp.</span>
                        <input type="text" v-model="fixFeeReferral" class="form-control" disabled
                               aria-label="Dollar amount (with dot and two decimal places)">
                      </div>
                      <div class="input-group">
                        <span class="input-group-text">Security Fee Referral</span>
                        <span class="input-group-text bg-gray-200">Rp.</span>
                        <input type="text" v-model="securityFeeReferral" class="form-control" disabled
                               aria-label="Dollar amount (with dot and two decimal places)">
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="col-md-8 form-control mb-3 bg-green-accent-4">
                      <div class="input-group mb-3">
                        <span class="input-group-text">Fix Fee Supplier</span>
                        <span class="input-group-text bg-gray-200">Rp.</span>
                        <input type="text" v-model="fixFeeSupplier" class="form-control" disabled
                               aria-label="Dollar amount (with dot and two decimal places)">
                      </div>
                      <div class="input-group">
                        <span class="input-group-text">Security Fee Supplier</span>
                        <span class="input-group-text bg-gray-200">Rp.</span>
                        <input type="text" v-model="securityFeeSupplier" class="form-control" disabled
                               aria-label="Dollar amount (with dot and two decimal places)">
                      </div>
                    </div>
                    <div class="input-group mb-3">
                      <span class="input-group-text">Total Fee</span>
                      <span class="input-group-text bg-gray-200">Rp.</span>
                      <input type="text" v-model="totalfee" class="form-control" disabled
                             aria-label="Dollar amount (with dot and two decimal places)">
                    </div>
                    <div class="input-group mb-3">
                      <span class="input-group-text">{{text}}</span>
                      <span class="input-group-text bg-gray-200">Rp. </span>
                      <input type="text" v-model="partneramount" class="form-control" disabled
                             aria-label="Dollar amount (with dot and two decimal places)">
                    </div>
                  </div>
                  <div class="col-md-4">
                    <button type="button" @click="calculate(datatosend)" class="btn btn-primary text-white">Calculate
                    </button>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <div class="alert alert-light" role="alert">
                      Total Fee : {{terbilangfee}}
                    </div>
                    <div class="alert alert-light" role="alert">
                      Total Partner Money : {{terbilangpartner}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>

</template>

<script>
import NavigationTop from "@/components/NavigationTop";
import DashboardService from "@/services/dashboard.service";

export default {
  name: "PageFeeCalculator",
  components: {
    NavigationTop,
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    }
  },
  data: () => ({
    response: '',
    datatosend: {
      mode: 'payin',
      amount: 0,
      toppay: {
        sfee: 0,
        smodel: 'F',
        ffee: 0,
        fmodel: 'F',
      },
      referral: {
        sfee: 0,
        smodel: 'F',
        ffee: 0,
        fmodel: 'F',
      },
      supplier: {
        sfee: 0,
        smodel: 'F',
        ffee: 0,
        fmodel: 'F',
      },
    },
    securityFeeTopay: 0,
    fixFeeTopay: 0,
    securityFeeReferral: 0,
    fixFeeReferral: 0,
    securityFeeSupplier: 0,
    fixFeeSupplier: 0,
    totalfee: 0,
    partneramount: 0,
    terbilangfee:'',
    terbilangpartner:'',
    text: 'Dana ke Partner'
  }),
  watch:{
    "datatosend.mode":{
      immediate: true,
      deep: true,
      handler(){
        if(this.datatosend.mode==='payin'){
          this.text = 'Dana ke Partner'
        }else if(this.datatosend.mode==='payout'){
          this.text = 'Dana Partner di Potong'
        }
      }
    }
  },
  methods: {
    calculate(data) {
      DashboardService.calculatefee(data).then(
          (response) => {
            if (response.data.code == 1) {
              this.response = response.data.data;
              this.securityFeeTopay = this.response.toppay.securityFeeValue;
              this.fixFeeTopay = this.response.toppay.fixFeeValue;

              this.securityFeeReferral = this.response.referral.securityFeeValue;
              this.fixFeeReferral = this.response.referral.fixFeeValue;

              this.securityFeeSupplier = this.response.supplier.securityFeeValue;
              this.fixFeeSupplier = this.response.supplier.fixFeeValue;

              this.partneramount = this.response.amount;
              this.totalfee = this.response.totalFee;
              this.terbilangfee = this.response.terbilangTotalFee;
              this.terbilangpartner = this.response.terbilangAmount;
            } else {
              this.response = response.data.error;
            }
          }
      )
    }
  }
}
</script>

<style scoped>

</style>