<template>
     <div class="card card-frame">
        <div class="card-header">
            <button class="btn btn-icon btn-3 btn-outline-primary mt-2" @click="$router.go(-1)">
                <span class="btn-inner--icon"><i class="fa fa-arrow-left"></i></span>
            </button>
            Product 
        </div>
        <div class="card-body"> 
            <div class="row" v-if="isError"> 
                <div class="col"> 
                <div class="alert alert-danger alert-dismissible fade show" role="alert">
                    <span class="alert-text text-white"><strong>Error!</strong> {{errorMsg}}</span>
                    <button class="btn-close" data-bs-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                </div>
            </div>
            <form>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-control-label">Product ID</label>
                            <input type="number" class="form-control" v-model="dataJsonInfo.form_p_id" placeholder="Product ID">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-control-label">Product Name</label>
                            <input type="text" class="form-control" v-model="dataJsonInfo.form_p_name" placeholder="Product Name">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-control-label">Link to PG</label>
                            <VueMultiselect
                                :multiple="false"
                                v-model="dataJsonInfo.form_p_pg_id"
                                :options="refPgs"
                                label="pg_name"
                                track-by="pg_id"
                                placeholder="Pick category"
                            >
                            </VueMultiselect>
                        </div>
                        <div class="form-group">
                            <label class="form-control-label">PG Config</label>
                            <textarea rows="3" class="form-control" v-model="dataJsonInfo.form_p_pg_config" 
                                placeholder="PG Config">
                            </textarea>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-control-label">Category</label>
                            <VueMultiselect
                                :multiple="false"
                                v-model="dataJsonInfo.form_p_category"
                                :options="refProductCategs"
                                label="cat_name"
                                track-by="cat_id"
                                placeholder="Pick category"
                            >
                            </VueMultiselect>
                        </div>
                        <div class="form-group">
                            <label class="form-control-label">Settlement Days</label>
                            <div class="input-group mb-3">
                                <input type="number" class="form-control" v-model="dataJsonInfo.form_p_day" placeholder="Settlement Days" aria-label="Settlement Days" aria-describedby="settlement-days">
                                <!-- <div class="input-group-append">
                                    <span class="input-group-text bg-success" id="settlement-days">Days</span>
                                </div> -->
                            </div>
                        </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                          <label class="form-control-label">Is Active</label>
                          <div class="form-check form-switch">
                            <input class="form-check-input bg-success" type="checkbox" v-model="dataJsonInfo.form_p_active" 
                              true-value="1" false-value="0">
                          </div>
                      </div>
                    </div>
                  </div>
            </form>
            <div class="row">
                <div class="col">
                    <price-table :item="dataJsonInfo.price" :payment-id="dataJsonInfo.form_p_id" :payment-name="dataJsonInfo.form_p_name" @add-item="addItem"></price-table>
                </div>
            </div>
            <div class="row mt-2" v-if="status != 'A'">
                <div class="col-12 col-md-12"> 
                    <button @click="submit()" class="btn btn-success mr-2" :disabled="loading_partner" >Submit</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import VueMultiselect from 'vue-multiselect'
import useValidate from '@vuelidate/core'
import {required,numeric} from '@vuelidate/validators'
import RequestService from "@/services/request.service";
import e2payApis from "@/services/e2pay-apis";
import PriceTable from './PriceTable.vue';

export default {
  name: "ProductForm",
  props:{
    // paramModel: ,
    // collapsed: Boolean,
  },
  components: {
    VueMultiselect,
    PriceTable
  },
  mounted() {
    this.fetchData();
  },
  methods:{
    fetchData(){
        RequestService.postRequest(e2payApis.GET_PAYMENT_GATEWAYS, {}).then(
          (response)=>{
            if(response.data.code==1){
              this.refPgs = response.data.data.data;
              this.loading = false;
            }else{
              this.loading = false;
              this.isError = true;
              this.errorMsg = response.data.message;
            }
          }
        )
        RequestService.postRequest(e2payApis.GET_PRODUCT_CATEGORIES, {}).then(
          (response)=>{
            if(response.data.code==1){
              this.refProductCategs = response.data.data;
              this.loading = false;
            }else{
              this.loading = false;
              this.isError = true;
              this.errorMsg = response.data.message;
            }
          }
        )
        if (this.$route.params.id !== undefined) {
          this.loading = true;
          RequestService.postRequest(e2payApis.GET_PRODUCT_PRICES, {
            filter_paymentid: this.$route.params.id,
            filter_pgid: this.$route.query.pg_id
          }).then(
              (response)=>{
                let items = response.data.data.data;
                if (items.length > 0) {
                  let item = items[0];
                  this.dataJsonInfo.form_p_id = item.payment_id;
                  this.dataJsonInfo.form_p_name = item.product_name;
                  this.dataJsonInfo.form_p_pg_id = this.refPgs.find(option => option.pg_id === item.pg_id)
                  this.dataJsonInfo.form_p_active = item.is_active;
                  this.dataJsonInfo.form_p_category = this.refProductCategs.find(option => option.cat_id === item.category);
                  this.dataJsonInfo.form_p_day = item.settlement_days;
                  this.dataJsonInfo.form_p_pg_config = item.pg_config;
                  this.dataJsonInfo.price = item.price;
                }
              }
          );
        }
    },
    addItem(prices) {
      this.dataJsonInfo.price = prices;
    },
    submit() {
        this.dataJsonInfo.form_p_pg_id = this.dataJsonInfo.form_p_pg_id.pg_id;
        this.dataJsonInfo.form_p_category = this.dataJsonInfo.form_p_category.cat_id;
        RequestService.postRequest(e2payApis.POST_SUBMIT_PRODUCT, this.dataJsonInfo).then(
          (response)=>{
            if(response.data.code==1){
              this.loading = false;
              this.$router.go(-1);
            }else{
              this.loading = false;
              this.isError = true;
              this.errorMsg = response.data.message;
            }
          }
        )
    }
  },
  data:()=>({
    v$: useValidate(),
    loading_partner: false,
    partnerInfoIcon: 'fa-solid fa-chevron-down',
    partner_info_collapsed: false,
    registerMessage:'',
    isDisabledCryptoModal: true,
    isError: false,
    errorMsg: '',
    status: '',
    refPgs:[],
    refProductCategs: [],
    dataJsonInfo: {
      form_p_id: '',
      form_p_name: '',
      form_p_pg_id: null,
      form_p_active: 1,
      form_p_category: '',
      form_p_day: '',
      form_p_pg_config: '',
      price:[]
    },
  }),
  validations() {
    return {
      dataJsonInfo: {
        form_p_id:{required,numeric},
        // form_p_disbusrment_approved:{required,numeric},
        // form_p_local_bank_approved:{required,numeric},
        // form_p_crypto_approved:{required,numeric},
        // form_p_overdraft_approved:{required,numeric},
        // form_p_description:{minLength:minLength(5)},
      }
    }
  },
}


</script>