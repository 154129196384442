<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <v-row justify="space-between">
          <v-col>
            <button @click="doFilter" class="btn btn-primary" >Filter <i :class="filterIcon"></i></button>
          </v-col>
          <v-col v-if="cardInfo">
            <TopShapeItem :customCols="'col'"  :loading="loading" menulink="/settlement" title="Ready To Settle" :value="settle" subvalue="" :imgsrc="require('@/assets/img/idr-icon.png')"></TopShapeItem>
          </v-col>
          <v-col v-if="cardInfo">
            <TopShapeItem :customCols="'col'" :loading="loading"  title="Receivables" :value="receivables" subvalue="" :imgsrc="require('@/assets/img/idr-icon.png')"></TopShapeItem>
          </v-col>
          <v-col class="text-right" v-if="can_download"> 
            <v-menu class="mr-3">
              <v-list>
                <v-list-item
                  v-for="(item, index) in downloadItems"
                  :key="index"
                  :value="index"
                  @click="f_submitdownload(item.id, dataJson)"
                >
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
        <div v-show="collapsed" class="card-body">
          <h5 class="font-weight-bolder">Filter</h5>
          <div class="row">
            <div class="col-md-3" >
              <label>Chart Type</label>
              <select class="form-control" v-model="dataJson.chartType">
                <option value="Payin">Payin</option>
                <option value="Payout">Payout</option>
              </select>
            </div>
            <div class="col-md-3" >
              <label>Merchant Code</label>
              <VueMultiselect
                  :multiple="true"
                  v-model="dataJson.filter_partner_code"
                  :options="dataMerchant"
                  :taggable="false"
                  label="text"
                  track-by="id"
                  placeholder="Pick Merchant Code"
              >
              </VueMultiselect>
            </div>
            <div class="col-md-3">
              <label>Start Date</label>
              <input class="form-control" type="date" v-model="dataJson.filter_date_range_start">
            </div>
            <div class="col-md-3">
              <label>End Date</label>
              <input class="form-control" type="date" v-model="dataJson.filter_date_range_end">
            </div>
          </div>
          <div class="card-body" v-if="f_loading">
            <img :src="imageLoading" style="width: 100px; height: 80px;"/>
          </div>
          <div class="card-body" v-else>
            <div class="row">
              <div class="col-md-3">
                <button @click="f_submit(dataJson)" class="btn btn-success mr-2" type="button" >Search</button>
                <button @click="doClear" class="btn btn-info" type="button" >Clear</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardService from "@/services/dashboard.service";
import TopShapeItem from "@/components/TopShapeItem";

export default {
  name: "ChartFilter",
  components:{
    TopShapeItem
  },
  props:{
    f_loading: Boolean,
    f_action: String,
    f_feemodel: Boolean,
    f_submit:undefined,
    f_submitdownload: undefined,
    can_download: Boolean,
    can_show_chart: Boolean,
    expand: Boolean,
    balanceData: Object,
    cardInfo: Boolean,
  },
  emits:['toggleExpand'],
  methods:{
    validateNumber: (event) => {
      let keyCode = event.keyCode;
      if (keyCode < 48 || keyCode > 57) {
        event.preventDefault();
      }
    },
    doFilter(){
        this.collapsed = !this.collapsed;
        if(this.collapsed){
          this.filterIcon = 'fa-solid fa-chevron-up';
        }else{
          this.filterIcon = 'fa-solid fa-chevron-down';
        }
    },
    doClear(){
      this.dataJson.chartType = '';
      this.dataJson.filter_date_range_start = '';
      this.dataJson.filter_date_range_end = '';
      this.dataJson.filter_partner_code = '';
      this.channelData = [];
    },
    getFeeModel(){
      this.loading = true;
      DashboardService.getFeeModel(this.dataJsonInfo).then(
          (response)=>{
            if(response.data.code==1){
              this.modelOptions = response.data.data;
              this.loading = false;
            }else{
              this.loading = false;
            }
          }
      )
    },
    loadChannelData(){
      this.loading = true;
      DashboardService.getChannelList(this.f_action).then(
          (response)=>{
            if(response.data.code==1){
              this.dataChannel = response.data.data;
              this.loading = false;
            }else{
              this.loading = false;
            }
          }
      )
    },
    loadStatusData(){
      this.loading = true;
      DashboardService.getStatusCodeList(this.dataJson).then(
          (response)=>{
            if(response.data.code==1){
              this.dataStatus = response.data.data;
              this.loading = false;
            }else{
              this.loading = false;
            }
          }
      )
    },
    onSelectStatus(option){
      this.dataJson.filter_status = option.sts;
    },
    onRemove(){
      this.dataJson.filter_channel = '';
    },
    onRemoveStatus(){
      this.dataJson.filter_status = '';
    },
    onSelect (option) {
      this.dataJson.filter_channel = option.payment_id;
    },
    prepareMount(){
      this.loading = true;
      DashboardService.getPartnerLink(this.dataJson).then(
          (response)=>{
            if(response.data.code==1){
              this.dataMerchant = response.data.data;
              this.loading = false;
            }else{
              this.loading = false;
            }
          }
      )
    }
  },
  data:()=>({
    form:'',
    control:'',
    collapsed: false,
    filterIcon: 'fa-solid fa-chevron-down',
    imageLoading: require('@/assets/img/cube_loading.gif'),
    select2settingP:{ dropdownAutoWidth: false,multiple:true,width: '100%'},
    loading: true,
    dataMerchant:[{}],
    modelOptions:[],
    channelData:[],
    statusData:[],
    dataChannel:[],
    dataStatus: [],
    dataJson:{
      download:false,
      chartType: '',
      filter_partner_code: '',
      filter_date_range_start:'',
      filter_date_range_end:''
    },
    settle:0,
    receivables: 0,
    downloadItems: [
      { title: 'As CSV', id:'csv' },
      { title: 'As Excel', id:'excel' },
    { title: 'As PDF', id: 'pdf' }
    ]
  }),
  beforeMount() {
    this.prepareMount();
    this.loadChannelData();
    this.loadStatusData();
    this.getFeeModel();
  },
  watch: {
    expand: function() { 
      
    },
    balanceData: {
      immediate: false,
      deep: true,
      handler(){
        this.settle = this.balanceData.total.ready_settle;
        this.receivables = this.balanceData.total.receivables;
      }
    }
  }
}
</script>

<style scoped>

</style>