<template>
  <div v-for="(message,index) in messagesList" :key="index" class="alert alert-dismissible fade" :class="message.classinfo+' show'" role="alert">
    <strong></strong> {{message.message_text}}
    <button @click="cancelTimer" type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"><i class="fa fa-close text-primary"></i></button>
  </div>
</template>

<script>
import DashboardService from "@/services/dashboard.service";
export default {
  name: "ToppayNotification",
  props:{
    menu: String,
  },
  methods:{
    cancelTimer(){
      clearInterval(this.interval);
    },
    countDownTimer() {
      this.interval = setInterval(() => {
        if (this.countDown > 0) {
          this.countDown--;
        } else {
          if(this.messagesList.length>0){
            this.infoIndex = this.infoIndex + 1;
            if(this.infoIndex>this.messagesList.length){
              this.infoIndex = 0;
              this.nextLoading++;
            }
            this.message = this.messagesList[Math.abs(this.infoIndex) % this.messagesList.length].message_text;
            this.countDown = this.messagesList[Math.abs(this.infoIndex) % this.messagesList.length].display_time;
            this.cssClass = this.messagesList[Math.abs(this.infoIndex) % this.messagesList.length].classinfo;
            if(this.nextLoading>10){
              this.nextLoading = 0;
              this.getInformation();
            }
          }else{
            this.countDown = 30;
            this.getInformation();
          }
        }
      }, 1000);
    },
    getInformation(){
      this.loading = true;
      DashboardService.getItemInformation({menu: this.menu}).then(
          (response)=>{
            if(response.data.code==1){
              this.infoIndex = 0;
              this.msgcount = response.data.data.count;
              this.messagesList = response.data.data.messages;
              if(this.messagesList.length>0){
                this.message = this.messagesList[0].message_text;
                this.show = true;
              }
              this.loading = false;
            }else{
              this.loading = false;
            }
          }
      )
    },
  },
  data:()=>({
    nextLoading: 0,
    interval: undefined,
    countDown:30,
    infoIndex : 0,
    cssClass:'alert-light',
    msgcount: '',
    message:'',
    greeting:'',
    messagesList:[],
    show: false,
  }),
  mounted() {
    this.getInformation();
    //this.countDownTimer();
  }
}
</script>

<style scoped>

</style>