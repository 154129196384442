<template>
  <DashboardLeftMenu></DashboardLeftMenu>
  <TableMerchantList></TableMerchantList>
</template>

<script>
import DashboardLeftMenu from "@/components/DashboardLeftMenu";
import TableMerchantList from "@/views/TableMerchantList";
export default {
  name: "RouteMerchantList",
  components:{
    DashboardLeftMenu,
    TableMerchantList
  }
}
</script>

<style scoped>

</style>