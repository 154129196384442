<template>
  <div class="row">
    <div class="card-header bg-gray-200">Change Password <button @click="doViewFormPartner"><i :class="partnerInfoIcon"></i></button> </div>
    <div class="col-12 m-2" v-if="partner_info_collapsed">
      <span v-show="loading_password" class="spinner-border spinner-border-sm"></span>
      <fieldset :disabled="loading_password">
        <div class="form-group">
          <label for="form_p_oldpassword" class="form-label">Old Password</label>
          <input type="password" class="form-control" id="form_p_oldpassword" v-model="dataJsonInfo.form_p_oldpassword" placeholder="Old Password">
          <span v-if="v$.dataJsonInfo.form_p_oldpassword.$error">{{ v$.dataJsonInfo.form_p_oldpassword.$errors[0].$message }}</span>
        </div>
        <div class="form-group">
          <label for="form_p_newpassword" class="form-label">New Password</label>
          <input type="password" class="form-control" id="form_p_newpassword" v-model="dataJsonInfo.form_p_newpassword" placeholder="New Password">
          <span v-if="v$.dataJsonInfo.form_p_newpassword.$error">{{ v$.dataJsonInfo.form_p_newpassword.$errors[0].$message }}</span>
        </div>
        <div class="form-group">
          <label for="form_p_renewpassword" class="form-label">Re New Password</label>
          <input type="password" class="form-control" id="form_p_renewpassword" v-model="dataJsonInfo.form_p_renewpassword" placeholder="Retype New Password">
          <span v-if="v$.dataJsonInfo.form_p_renewpassword.$error">{{ v$.dataJsonInfo.form_p_renewpassword.$errors[0].$message }}</span>
        </div>
        <span class="d-block">{{changePasswordMessage}}</span>
        <span class="d-block">Password minimum 8 character</span>
        <div class="form-group">
          <button class="btn btn-primary mr-2" :disabled="!formok" @click="doChangePassword">Change Password</button>
        </div>
      </fieldset>
    </div>
  </div>
</template>

<script>
import DashboardService from "@/services/dashboard.service";
import useValidate from '@vuelidate/core'
import {required, minLength,maxLength} from '@vuelidate/validators'
export default {
  name: "ProfileChangePassword",
  computed:{
  },
  methods:{
    doViewFormPartner() {
      this.partner_info_collapsed = !this.partner_info_collapsed;
      if(this.partner_info_collapsed){
        this.partnerInfoIcon = 'fa-solid fa-chevron-up';
      }else{
        this.partnerInfoIcon = 'fa-solid fa-chevron-down';
      }
    },
    doChangePassword(){
      this.v$.dataJsonInfo.$validate();
      if(!this.v$.dataJsonInfo.$error){
        this.loading_password = true;
        DashboardService.changePassword(this.dataJsonInfo).then(
            (response)=>{
              this.changePasswordMessage = response.data.message;
              this.loading_password = false;
            }
        )

      }
    }
  },
  data:()=>({
    v$: useValidate(),
    formok: true,
    partnerInfoIcon: 'fa-solid fa-chevron-down',
    showForm: false,
    partner_info_collapsed: false,
    changePasswordMessage:'',
    loading_password: false,
    dataJsonInfo:{
      form_p_oldpassword:'',
      form_p_newpassword:'',
      form_p_renewpassword:''
    }
  }),
  watch:{
  },
  validations() {
    return {
      dataJsonInfo: {
        form_p_oldpassword:{required},
        form_p_newpassword: {required,maxLength:maxLength(150), minLength:minLength(8)},
        form_p_renewpassword: {required,maxLength:maxLength(150), minLength:minLength(8)},
      }
    }
  }
}
</script>

<style scoped>

</style>