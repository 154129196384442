<template>
  <!-- 
          <span v-show="loading_partner" class="spinner-border spinner-border-sm"></span>
          <span class="d-block" text-black>{{registerMessage}}</span>
          
          <div class="mb-3" v-else>
            <span>This Request already approved by {{dataJsonInfo.form_p_approve_by}} at {{dataJsonInfo.form_p_approve_date}}</span>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <div class="card card-frame">
    <div class="card-header">
      <button class="btn btn-icon btn-3 btn-outline-primary mt-2" @click="$router.go(-1)">
        <span class="btn-inner--icon"><i class="fa fa-arrow-left"></i></span>
      </button>
      Back To Topup Request 
    </div>
    <div class="card-body"> 
      <div class="row" v-if="isError"> 
        <div class="col"> 
          <div class="alert alert-danger alert-dismissible fade show" role="alert">
            <span class="alert-text text-white"><strong>Error!</strong> {{errorMsg}}</span>
            <button class="btn-close" data-bs-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      </div>
      <modal-crypto-form modal-id="exampleModalMessage" :partner-id="dataJsonInfo.partner_id" 
       :partner-name="dataJsonInfo.form_p_name" :form-crypto-detail="dataJsonInfo.form_crypto_detail[0]" @submit="cryptoSubmit"></modal-crypto-form>
      <form>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label">Merchant Id</label>
              <input type="text" class="form-control" disabled="disabled" v-model="dataJsonInfo.form_p_id" placeholder="Partner Id">
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label">Merchant Name</label>
              <input type="text" class="form-control" disabled="disabled" v-model="dataJsonInfo.form_p_name" placeholder="Partner Name">
            </div>
          </div>
        </div>
        <div class="row" v-if="isSplitPayout == 1">
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label">Request Wallet</label>
              <input type="text" class="form-control" disabled="disabled" v-model="dataJsonInfo.form_p_wallet" placeholder="Request Wallet">
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label">Approval Wallet</label>
              <input type="text" class="form-control" :class="{'is-invalid' : v$.dataJsonInfo.form_p_wallet_approved.$error}" v-model="dataJsonInfo.form_p_wallet_approved" placeholder="Approval Wallet">
              <span class="invalid-feedback" v-if="v$.dataJsonInfo.form_p_wallet_approved.$error">{{ v$.dataJsonInfo.form_p_wallet_approved.$errors[0].$message }}</span>
            </div>
          </div>
        </div>
        <div class="row" v-if="isSplitPayout == 1">
          <div class="col-md-12">
            <div class="form-group">
              <label class="form-control-label">Approval Wallet Remarks</label>
              <textarea rows="3" class="form-control" v-model="dataJsonInfo.form_p_wallet_remarks" placeholder="Wallet Remarks">
              </textarea>
            </div>
          </div>
        </div>
        <div class="row" v-if="isSplitPayout == 1">
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label">Request Disbursement</label>
              <input type="text" class="form-control" disabled="disabled" v-model="dataJsonInfo.form_p_disbusrment" placeholder="Request Disbursement">
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label">Approval Disbursement</label>
              <input type="text" class="form-control" :class="{'is-invalid' : v$.dataJsonInfo.form_p_wallet_approved.$error}" v-model="dataJsonInfo.form_p_disbusrment_approved" placeholder="Approval Disbursement">
              <span class="invalid-feedback" v-if="v$.dataJsonInfo.form_p_disbusrment_approved.$error">{{ v$.dataJsonInfo.form_p_disbusrment_approved.$errors[0].$message }}</span>
            </div>
          </div>
        </div>
        <div class="row" v-if="isSplitPayout == 1">
          <div class="col-md-12">
            <div class="form-group">
              <label class="form-control-label">Approval Disbursement Remarks</label>
              <textarea rows="3" class="form-control" v-model="dataJsonInfo.form_p_disbusrment_remarks" placeholder="Disbursement Remarks">
              </textarea>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label">Request Local Bank</label>
              <input type="text" class="form-control" disabled="disabled" v-model="dataJsonInfo.form_p_local_bank" placeholder="Request Local Bank">
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label">Approval Local Bank</label>
              <input type="text" class="form-control" :class="{'is-invalid' : v$.dataJsonInfo.form_p_wallet_approved.$error}" v-model="dataJsonInfo.form_p_local_bank_approved" placeholder="Approval Local Bank">
              <span class="invalid-feedback" v-if="v$.dataJsonInfo.form_p_local_bank_approved.$error">{{ v$.dataJsonInfo.form_p_local_bank_approved.$errors[0].$message }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label class="form-control-label">Approval Local Bank Remarks</label>
              <textarea rows="3" class="form-control" v-model="dataJsonInfo.form_p_local_bank_remarks" placeholder="Disbursement Remarks">
              </textarea>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label">Request Crypto</label>
              <input type="text" class="form-control" disabled="disabled" v-model="dataJsonInfo.form_p_crypto" placeholder="Request Crypto">
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label">Approval Crypto</label>
              <div class="input-group mb-3">
                <input type="text" class="form-control"  v-model="dataJsonInfo.form_p_crypto_approved" placeholder="Approval Crypto" >
                <!-- <button class="btn btn-outline-primary mb-0" type="button" id="button-addon2">Button</button> -->
                <button type="button" class="btn bg-success mb-0" :disabled="isDisabledCryptoModal"
                  data-bs-toggle="modal" data-bs-target="#exampleModalMessage" >
                  View Form
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label class="form-control-label">Approval Crypto Remarks</label>
              <textarea rows="3" class="form-control" v-model="dataJsonInfo.form_p_crypto_remarks" placeholder="Crypto Remarks">
              </textarea>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label">Request Overdraft</label>
              <input type="text" class="form-control" disabled="disabled" v-model="dataJsonInfo.form_p_overdraft" placeholder="Request Overdraft">
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-control-label">Approval Overdraft</label>
              <input type="text" class="form-control" :class="{'is-invalid' : v$.dataJsonInfo.form_p_wallet_approved.$error}" v-model="dataJsonInfo.form_p_overdraft_approved" placeholder="Approval Overdraft">
              <span class="invalid-feedback" v-if="v$.dataJsonInfo.form_p_overdraft_approved.$error">{{ v$.dataJsonInfo.form_p_overdraft_approved.$errors[0].$message }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label class="form-control-label">Approval Overdraft Remarks</label>
              <textarea rows="3" class="form-control" v-model="dataJsonInfo.form_p_overdraft_remarks" placeholder="Overdraft Remarks">
              </textarea>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-12">
            <div class="form-group">
              <label class="form-control-label">Overall Remarks</label>
              <textarea rows="3" class="form-control" v-model="dataJsonInfo.form_p_description" placeholder="Description">
              </textarea>
              <span class="invalid-feedback" v-if="v$.dataJsonInfo.form_p_description.$error">{{ v$.dataJsonInfo.form_p_description.$errors[0].$message }}</span>
            </div>
          </div>
        </div>
        <div class="row" v-if="status != 'A'">
          <div class="col-12 col-md-12"> 
            <button @click="doAction(1)" class="btn btn-success mr-2" :disabled="loading_partner" type="button" >Approve</button>
            <button @click="doAction(2)" class="btn btn-warning mr-2" :disabled="loading_partner" type="button" >Reject</button>
          </div>
        </div>
        <!-- <div class="mb-3" v-if="product.topup_status==='R'">
            <span v-show="loading_partner" class="spinner-border spinner-border-sm"></span>
          </div> -->
      </form>
    </div>
  </div>
</template>

<script>
import DashboardService from "@/services/dashboard.service";
import useValidate from '@vuelidate/core'
import {required,numeric,minLength} from '@vuelidate/validators'
import ModalCryptoForm from "@/views/topup/ModalCryptoForm";
import RequestService from "@/services/request.service";
import e2payApis from "@/services/e2pay-apis";

export default {
  name: "TopupRequesForm",
  props:{
    // paramModel: ,
    // collapsed: Boolean,
  },
  components: {
    ModalCryptoForm
  },
  mounted() {
    this.fetchData();
    const loggedIn = localStorage.getItem('user');
    if(loggedIn != null){
        let dataUser = JSON.parse(loggedIn);
        this.isSplitPayout = dataUser.split_payout;
    }
  },
  methods:{
    doAction(action){
      this.v$.dataJsonInfo.$validate();
      if(!this.v$.dataJsonInfo.$error){
        console.log('asup');
        this.loading_partner = true;
        this.dataJsonInfo.form_action = action;
        DashboardService.approvalTopupRequest(this.dataJsonInfo).then(
            (response)=>{
              console.log(response);
              if(response.data.code==1){
                this.registerMessage = response.data.message;
                this.registerError = response.data.error;
                this.loading_partner = false;
                this.$router.go(-1)
              }else{
                this.registerMessage = response.data.message;
                this.registerError = response.data.error;
                this.loading_partner = false;
              }
            }
        )
      }else{
        console.log('validasi error');
        this.loading_partner = false;
        this.registerMessage = this.v$.dataJsonInfo.$errors[0].$message;
      }
    },
    fetchData(){
      if (this.$route.params.id !== undefined) {
        this.loading = true;
        DashboardService.getTopupRequestData({trxType: 'payin', topupId: this.$route.params.id}).then(
            (response)=>{
              let items = response.data.data.data;
              if (items.length > 0) {
                let item = items[0];
                this.dataJsonInfo.item = item;
                this.dataJsonInfo.partner_id = item.partner_id;
                this.dataJsonInfo.form_p_id = item.partner_code;
                this.dataJsonInfo.form_p_name = item.partner_name;
                this.dataJsonInfo.form_p_wallet = item.topup_wallet;
                this.dataJsonInfo.form_p_disbusrment = item.topup_disbursement;
                this.dataJsonInfo.form_p_local_bank = item.topup_local_bank;
                this.dataJsonInfo.form_p_crypto = item.topup_crypto;
                this.dataJsonInfo.form_p_overdraft = item.topup_overdraft;
                
                this.dataJsonInfo.form_p_wallet_approved = item.topup_wallet_approved_amt;
                this.dataJsonInfo.form_p_disbusrment_approved = item.topup_disbursement_approved_amt;
                this.dataJsonInfo.form_p_local_bank_approved = item.topup_local_bank_approved_amt;
                this.dataJsonInfo.form_p_crypto_approved = item.topup_crypto_approved_amt;
                this.dataJsonInfo.form_p_overdraft_approved = item.topup_overdraft_approved_amt;

                this.dataJsonInfo.form_p_wallet_remarks = item.topup_wallet_remarks;
                this.dataJsonInfo.form_p_disbusrment_remarks = item.topup_disbursement_remarks;
                this.dataJsonInfo.form_p_local_bank_remarks = item.topup_local_bank_remarks;
                this.dataJsonInfo.form_p_crypto_remarks = item.topup_crypto_remarks;
                this.dataJsonInfo.form_p_overdraft_remarks = item.topup_overdraft_remarks;
                this.dataJsonInfo.form_crypto_detail = item.form_crypto_detail;

                this.form_p_description = item.description;
                this.form_p_approve_by = item.user_approved;
                this.form_p_approve_date = item.user_topup;
                this.status = item.topup_status;
              }
            }
        );
      }
    },
    cryptoSubmit(formData) {
      formData.partner_id = this.dataJsonInfo.partner_id;
      RequestService.postRequest(e2payApis.POST_SUBMIT_CRYPTO, formData).then(
          (response)=>{
            console.log('reponse', response.data);
            if(response.data.code==1){
              this.form_p_crypto_form_id = response.data.crypto_id;
              this.loading = false;
            }else{
              this.loading = false;
              this.isError = true;
              this.errorMsg = response.data.message;
            }
          }
      )
    }
  },
  data:()=>({
    v$: useValidate(),
    loading_partner: false,
    partnerInfoIcon: 'fa-solid fa-chevron-down',
    partner_info_collapsed: false,
    registerMessage:'',
    isDisabledCryptoModal: true,
    isError: false,
    errorMsg: '',
    status: '',
    isSplitPayout: 1,
    dataJsonInfo: {
      partner_id: 0,
      form_p_id: '',
      form_p_name: '',
      form_p_wallet: '',
      form_p_disbusrment: '',
      form_p_local_bank: '',
      form_p_crypto:'',
      form_p_overdraft:'',
      form_p_wallet_approved:'0',
      form_p_disbusrment_approved:'0',
      form_p_local_bank_approved:'0',
      form_p_crypto_approved:'0',
      form_p_overdraft_approved:'0',
      form_p_wallet_remarks:'',
      form_p_disbusrment_remarks:'',
      form_p_local_bank_remarks:'',
      form_p_crypto_remarks:'',
      form_p_overdraft_remarks:'',
      form_p_description:'',
      form_p_approve_by:'',
      form_p_approve_date:'',
      form_action:2,
      form_p_crypto_form_id:'',
      wallet_address_trc20:'',
      form_crypto_detail: {},
      item:[]
    },
  }),
  validations() {
    return {
      dataJsonInfo: {
        form_p_wallet_approved:{required,numeric},
        form_p_disbusrment_approved:{required,numeric},
        form_p_local_bank_approved:{required,numeric},
        // form_p_crypto_approved:{required,numeric},
        form_p_overdraft_approved:{required,numeric},
        form_p_description:{minLength:minLength(5)},
      }
    }
  },
  watch:{
    'dataJsonInfo.form_p_crypto_approved':{
      immediate: true,
      deep: true,
      handler(){
        if (this.dataJsonInfo.form_p_crypto_approved != null && this.dataJsonInfo.form_p_crypto_approved != '' && this.dataJsonInfo.form_p_crypto_approved != '0') {
          this.isDisabledCryptoModal= false;
        } else {
          this.isDisabledCryptoModal= true;
        }
      }
    },
    // product:{
    //   immediate: true,
    //   deep: true,
    //   handler(){
    //     this.dataJsonInfo.item = this.product;
    //     this.dataJsonInfo.form_p_id = this.product.partner_code;
    //     this.dataJsonInfo.form_p_name = this.product.partner_name;
    //     this.dataJsonInfo.form_p_wallet = this.product.topup_wallet;
    //     this.dataJsonInfo.form_p_disbusrment = this.product.topup_disbursement;
    //     this.dataJsonInfo.form_p_crypto = this.product.topup_crypto;
    //     this.dataJsonInfo.form_p_overdraft = this.product.topup_overdraft;

    //     this.dataJsonInfo.form_p_wallet_approved = this.product.appr_wallet;
    //     this.dataJsonInfo.form_p_disbusrment_approved = this.product.appr_disbursement;
    //     this.dataJsonInfo.form_p_crypto_approved = this.product.appr_crypto;
    //     this.dataJsonInfo.form_p_overdraft_approved = this.product.appr_overdraft;
    //     this.dataJsonInfo.form_p_description = this.product.appr_desc;

    //     this.dataJsonInfo.form_p_approve_by = this.product.appr_by;
    //     this.dataJsonInfo.form_p_approve_date = this.product.approve_date;
    //   }
    // }
  }
}
</script>

<style scoped>

</style>