<template>
  <DashboardLeftMenu></DashboardLeftMenu>
  <GenerateMBayarVA></GenerateMBayarVA>
</template>

<script>
import DashboardLeftMenu from "@/components/DashboardLeftMenu";
import GenerateMBayarVA from "@/views/GenerateMBayarVA";
export default {
  name: "RouterPageGenerateVA",
  props:{
  },
  components:{
    GenerateMBayarVA,
    DashboardLeftMenu,
  }
}
</script>

<style scoped>

</style>