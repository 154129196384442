<template>
     <v-dialog
        persistent
        v-model="dialog"
        height="auto"
      >
        <div class="card card-frame">
            <div class="card-header">
              <span class="text-h5">Overdraft Summary Detail</span>
            </div>
            <div class="card-body"> 
                <form>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div class="row">
                                <label for="staticEmail" class="col-4 col-form-label font-weight-normal">Trx ID</label>
                                <div class="col-7">
                                    <input type="text" readonly class="form-control-plaintext font-weight-bold"  :value="formDetail.trx_id">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div class="row">
                                <label for="staticEmail" class="col-4 col-form-label font-weight-normal">Requested Datetime</label>
                                <div class="col-7">
                                    <input type="text" readonly class="form-control-plaintext font-weight-bold"  :value="formDetail.requested_at">
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div class="row">
                                <label for="staticEmail" class="col-4 col-form-label font-weight-normal">Requested By</label>
                                <div class="col-7">
                                    <input type="text" readonly class="form-control-plaintext font-weight-bold"  :value="formDetail.requested_user">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div class="row">
                                <label for="staticEmail" class="col-4 col-form-label font-weight-normal">Merchant</label>
                                <div class="col-7">
                                    <input type="text" readonly class="form-control-plaintext font-weight-bold"  :value="formDetail.partner_name">
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div class="row">
                                <label for="staticEmail" class="col-4 col-form-label font-weight-normal">Type</label>
                                <div class="col-7">
                                    <input type="text" readonly class="form-control-plaintext font-weight-bold"  :value="formDetail.overdraft_type_desc">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div class="row">
                                <label for="staticEmail" class="col-4 col-form-label font-weight-normal">Amount</label>
                                <div class="col-7">
                                    <input type="text" readonly class="form-control-plaintext font-weight-bold"  :value="formDetail.amount_in_string">
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div class="row">
                                <label for="staticEmail" class="col-4 col-form-label font-weight-normal">Status</label>
                                <div class="col-7">
                                    <input type="text" readonly class="form-control-plaintext font-weight-bold"  :value="formDetail.status_desc">
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="row mt-2" v-if="status != 'A'">
                    <div class="col-12 col-md-12"> 
                        <button @click="this.$emit('closeDialog')" class="btn btn-block btn-info mr-2" :disabled="loading" >Close</button>
                    </div>
                </div>
            </div>
        </div>
    </v-dialog>
</template>

<script>
// import {formatNumber, getFormattedNumber} from "@/services/number-format"


export default {
    name: "ModalOverdraftDetail",
    props:{
        modalId: String,
        openDialog: Boolean,
        formDetail: Object,
    },
    data:()=>({
        dialog: false,
    }),
    emits:['closeDialog'],
    watch: {
        openDialog: function(newVal) {
            this.dialog = newVal;
        },
        formDetail: function(newVal) { 
            console.log('newval', newVal)
        }
    }

}

</script>