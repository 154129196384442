<template>
  <main class="main-content">
    <NavigationTop :pages=currentRouteName :subpages=currentRouteName></NavigationTop>
    <div class="container-fluid py-4">
      <div class="row">
        <div class="col-12">
          <div class="card mb-4">
            <div class="card-header pb-0">
              <h6>Data {{currentRouteName}}</h6>
            </div>
            <div class="card">
              <div class="card-body p-3">
                <TableFilter :f_submit="searchSubmit" :f_loading=loading :f_partner_code=true :f_trx_type=true
                  :f_date_range=true :f_refno=true :f_merchantrefno="true"></TableFilter>
              </div>
              <div class="card-body p-3">
                <div class="row">
                  <ShapeInfo :customCols="'col'" :loading="loading" :imgsrc="require('@/assets/img/idr-icon.png')" title="Daily Topup Amount" :value="totalTopupAmount"></ShapeInfo>
                  <ShapeInfo :customCols="'col'" :loading="loading" :imgsrc="require('@/assets/img/idr-icon.png')" :isReadyToSettle=true title="Remaining Balance" :value="currentBalance"></ShapeInfo>
                  <ShapeInfo :customCols="'col'" :loading="loading" :imgsrc="require('@/assets/img/idr-icon.png')" title="Accumulated Topup Amount" :value="accumulatedTopupAmount" :subvalue="subvalue"></ShapeInfo>
                </div>
                <div class="row mt-4">
                  <EasyDataTable
                      show-index
                      buttons-pagination
                      alternating
                      v-model:server-options="serverOptions"
                      :server-items-length="numberOfPages"
                      :loading="loading"
                      :headers="headers"
                      :items="balancehistory"
                      :search-field="searchField"
                      :search-value="searchValue"
                      :body-row-class-name="rowColorFunction"
                  >
                    <template #expand="item">
                      <div style="padding: 15px">
                        {{item.trx_id}} {{item.trxtype}} {{item.trxDate}} {{item.amount}}
                      </div>
                    </template>
                    <template #item-trxtype="{trxtype}">
                      <div class="bg-warning text-center" v-if="trxtype=='REVERSAL'">
                        <span class="badge badge-warning">{{trxtype}}</span>
                      </div>
                      <div class="bg-info text-center" v-else-if="trxtype=='TOP UP' || trxtype=='OVERDRAFT'">
                        <span class="badge badge-info">{{trxtype}}</span>
                      </div>
                      <div class="bg-success text-center" v-else>
                        <span class="badge badge-success">{{trxtype == 'PAYMENT'? "MERCHANT WITHDRAWAL" : trxtype}}</span>
                      </div>
                    </template>
                    <template #item-netamount="{netamount,trxtype}">
                      <span v-if="netamount == null" class="text-bold"></span>
                      <span v-else-if="trxtype=='REVERSAL' || trxtype=='TOP UP' || trxtype=='OVERDRAFT'" class="text-bold">+{{netamount}}</span>
                      <span v-else class="text-bold">-{{netamount}}</span>
                    </template>
                    <template #loading>
                      <img :src="imageLoading" style="width: 100px; height: 80px;"/>
                    </template>
                  </EasyDataTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import DashboardService from "@/services/dashboard.service";
import NavigationTop from "@/components/NavigationTop";
import TableFilter from "@/views/TableFilter";
import ShapeInfo  from "@/components/ShapeInfo";
export default {
  name: "TableBalanceHistory",
  components:{
    NavigationTop,
    TableFilter,
    ShapeInfo
  },
  props: {
    ballType: String
  },
  computed:{
    currentRouteName() {
      return this.$route.name;
    }
  },
  methods:{
    searchSubmit(dataParam){
      this.searchParam = dataParam;
      this.searchParam.ballType = this.ballType;
      this.searchParam.serverOptions = this.serverOptions;
      this.fetchData();
    },
    fetchData(){
      this.loading = true;
      this.searchParam.ballType = this.ballType;
      this.searchParam.serverOptions = this.serverOptions;
      DashboardService.getBalanceHistory(this.searchParam).then(
          (response)=>{
            this.totalTopupAmount = response.data.data.totalTopupAmount;
            this.accumulatedTopupAmount = response.data.data.accumulatedTopupAmount;
            this.currentBalance = response.data.data.currentBalance;
            this.balancehistory = response.data.data.data;
            this.numberOfPages = response.data.data.dataCount;
            this.loading = false;
          }
      );
    },
    rowColorFunction(item) {
      if (item.trxtype == 'REVERSAL') return 'reversal-row';
      else if (item.trxtype == 'TOP UP') return 'topup-row';
      else if (item.trxtype == 'OVERDRAFT') return 'overdraft-row';
      return '';
    }
  },
  data:()=>({
    imageLoading: require('@/assets/img/cube_loading.gif'),
    numberOfPages : 0,
    balancehistory: [],
    content:"",
    searchField:undefined,
    searchValue:'',
    loading: false,
    totalTopupAmount: "0",
    currentBalance: "0",
    accumulatedTopupAmount: "0",
    subvalue: 'Until MM-YYYY',
    headers: [
      { text: "Transaction Id", value: "trx_id" },
      { text: "Timestamp", value: "trxDate" },
      { text: "Partner Code", value: "partner_code", sortable: true },
      { text: "Partner Name", value: "partner_name", sortable: true },
      { text: "Previous Balance (Rp.)", value: "prev_balance" , sortable: true},
      { text: "Top Up Amount (Rp.)", value: "topUpAmount" , sortable: true},
      { text: "Payout Amount (Rp.)", value: "amount" },
      { text: "Admin Fee (Rp.)", value: "feeAmount" },
      { text: "Settlement Amount (Rp.)", value: "netamount" },
      { text: "Ending Balance (Rp.)", value: "curr_balance" , sortable: true},
      { text: "Type", value: "trxtype" },
      { text: "Transaction Ref No", value: "payid" },
      { text: "Merchant Ref No", value: "infodesc" },
    ],
    searchParam:{},
    serverOptions:{
      page: 1,
      rowsPerPage: 25,
      sortBy:'',
      sortType:'',
    }
  }),
  mounted() {
    this.fetchData();
  },
  watch:{
    serverOptions:{
      handler(){
        this.searchParam.serverOptions = this.serverOptions;
        this.fetchData(this.searchParam);
      },
      deep: true
    }
  }
}
</script>

<style >
  tr.reversal-row td{
    background-color: #fbff30 !important;
  }
  tr.topup-row td{
    background-color: #2df13a !important;
  }
  tr.overdraft-row td{
    background-color: #2df13a !important;
  }
</style>