<template>
    <div class="container-fluid py-4">
        <div class="row">
            <div class="col-12">
                <div class="card mb-12">
                    <div class="card-header pb-0">
                        <div class="row">
                            <div class="col">
                                <h6>Data {{currentRouteName}}</h6>
                            </div>
                            <div class="col"> 
                                <router-link to="/partner/add" class="btn btn-primary float-right margin-0 pd-10">
                                    <span><i class="fas fa-plus-circle"></i></span>
                                    Add
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-body p-3">
                            <TableFilter :f_submit="searchSubmit" :f_loading=loading :f_name=true :f_paymentid=true :f_category=true></TableFilter>
                        </div>
                        <div class="card-body p-3">
                            <EasyDataTable
                                show-index
                                buttons-pagination
                                alternating
                                v-model:server-options="serverOptions"
                                :server-items-length="numberOfPages"
                                :loading="loading"
                                :headers="headers"
                                :items="transactions"
                                :search-field="searchField"
                                :search-value="searchValue"
                            >
                                <!-- <template #expand="item">
                                    <div style="padding: 15px">
                                        {{item.partner_code}} named {{item.partner_name}} allowed channel {{item.partner_allow_channel}}
                                    </div>
                                </template> -->
                                <template #item-partner_code="item">
                                    <span class="text-bold" @click="showPartner(item)"><a href="#">{{item.partner_code}}</a></span>
                                </template>
                                <template #item-partner_name="{partner_name}">
                                    <span>{{partner_name}}</span>
                                </template>
                                <template #item-partner_status="{partner_status}">
                                    <div class="bg-success text-center" v-if="partner_status==1">
                                        <span class="badge badge-success">Active</span>
                                    </div>
                                    <div class="bg-gray-600 text-center" v-else>
                                        <span class="badge badge-warning">Not Active</span>
                                    </div>
                                </template>
                                <template #item-partner_id="item">
                                    <router-link :to="'/partner/'+item.partner_code+'/view'" class="btn btn-success my-3">
                                        Edit
                                    </router-link>
                                </template>
                                <template #loading>
                                    <img :src="imageLoading" style="width: 100px; height: 80px;"/>
                                </template>
                            </EasyDataTable>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import DashboardService from "@/services/dashboard.service";
  import TableFilter from "@/views/TableFilter";
  export default {
    name: "TablePartners",
    components: {
      TableFilter,
    },
    computed:{
      currentRouteName() {
        return this.$route.name;
      }
    },
    methods:{
      searchSubmit(dataParam){
        this.searchParam = dataParam;
        this.searchParam.trxType = 'payin';
        this.searchParam.serverOptions = this.serverOptions;
        this.fetchData();
      },
      viewItem(item){
        this.isEdit = true;
        this.itemData = item;
      },
      fetchData(){
        this.loading = true;
        this.searchParam.trxType = 'partners';
        this.searchParam.serverOptions = this.serverOptions;
        DashboardService.getPartnersData(this.searchParam).then(
            (response)=>{
                this.transactions = response.data.data.data;
                this.numberOfPages = response.data.data.dataCount;
                this.loading = false;
            }
        );
      }
    },
    data:()=>({
      imageLoading: require('@/assets/img/cube_loading.gif'),
      transactions: [],
      content:"",
      numberOfPages : 0,
      searchField:'refNo',
      searchValue:'',
      loading: false,
      itemData:[],
      isEdit: false,
      headers: [
      { text: "Merchant Code", value: "partner_code" , sortable: true},
      { text: "Merchant Name", value: "partner_name" , sortable: true},
      { text: "Address", value: "partner_address" },
      { text: "Phone Number", value: "partner_telp" },
      { text: "Secret Key", value: "partner_secret" },
      { text: "Status", value: "partner_status" , sortable: true},
      { text: "Allowed IP Address", value: "partner_allow_ip" },
      { text: "Whitelist IP Address", value: "partner_whitelist_allow_ip" },
      { text: "Linked Channel", value: "partner_allow_channel" },
      { text: "Wallet Balance", value: "partner_balance_wallet", sortable: true },
      { text: "Disbursment Balance", value: "partner_balance_disbursment", sortable: true },
      { text: "Edit", value: "partner_id", sortable: true }
      ],
      searchParam:{},
      serverOptions:{
        page: 1,
        rowsPerPage: 25,
        sortBy:'',
        sortType:'',
      }
    }),
    mounted() {
      this.fetchData();
    },
    watch:{
      serverOptions:{
        handler(){
          this.searchParam.serverOptions = this.serverOptions;
          this.fetchData(this.searchParam);
        },
        deep: true
      }
    }
  }
  </script>
  
  <style scoped>
  
  </style>