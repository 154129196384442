<template>
  <DashboardLeftMenu></DashboardLeftMenu>
  <TablePayout :model=model></TablePayout>
</template>

<script>
import DashboardLeftMenu from "@/components/DashboardLeftMenu";
import TablePayout from "@/views/TablePayout";
export default {
  name: "RouterPagePayout",
  props:{
    model:String
  },
  components:{
    TablePayout,
    DashboardLeftMenu,
  }
}
</script>

<style scoped>

</style>