<template>
  <div class="col-12 position-relative">
    <div class="position-absolute top-0 end-0">
      <button @click="toggleCollapse"><i :class="icon"></i></button>
    </div>
  </div>
  <div class="col-12" v-show="!collapsed">
    <div class="row" v-if="loading">
      <img :src="imageLoading" class="w-10 rounded mx-auto d-block"/>
    </div>
    <div class="row" v-if="!loading">
      <div class="d-flex flex-column">
        <h6 class="mb-3 text-sm">Merchant name:<span class="text-uppercase">{{partnerData.partner_name}}</span></h6>
        <span class="mb-2 text-xs">Status: <span class="text-dark font-weight-bold ms-sm-2">{{partnerData.status}}</span></span>
        <span class="mb-2 text-xs">Partner Code: <span class="text-dark font-weight-bold ms-sm-2">{{partnerData.partner_code}}</span></span>
        <span class="mb-2 text-xs">Secret Key: <span class="text-dark font-weight-bold ms-sm-2">{{secretKey}}</span> <i @click="showSecret" :class=eyeicon class="cursor-pointer"></i></span>
        <span class="mb-2 text-xs">Mobile Phone: <span class="text-dark font-weight-bold ms-sm-2">{{partnerData.partner_telp}}</span></span>
        <span class="mb-2 text-xs">Address: <span class="text-dark ms-sm-2 font-weight-bold">{{partnerData.partner_address}}</span></span>
        <span class="mb-2 text-xs">Email Address: <span class="text-dark ms-sm-2 font-weight-bold">{{partnerData.partner_email}}</span></span>
        <span class="text-xs" v-if="partnerData.va!='-'">VA Number: <span class="text-dark ms-sm-2 font-weight-bold">{{partnerData.va}}</span></span>
      </div>
      <div class="card card-body border card-plain border-radius-lg" v-if="tampilkan">
        <div class="col-12">
          <ol class="text-xs mb-3">
            <li>Make sure all data correct, name, email and phone number</li>
            <li>Click Generate VA button to make request, OTP will send to mobile number</li>
            <li>Check text message for OTP, fill box below with OTP and then click submit for completing registration process</li>
          </ol>
          {{registrationRrror}}
          <button :disabled="disablevagenerate" @click="generateVA" class="btn bg-gradient-primary mb-0 mr-2" ><i class="fas fa-plus" aria-hidden="true"></i>Generate VA</button>
          <button @click="checkBalance" v-if="partnerData.va!='-'" class="btn bg-gradient-dark mb-0" ><i class="fas fa-plus" aria-hidden="true"></i>Info</button>
        </div>
      </div>
      <div class="row" v-if="loadingregister">
        <img :src="imageLoading" class="w-10 rounded mx-auto d-block"/>
      </div>
      <div v-if="showOtpBox">
        <div class="col-md-12 mb-md-0 mt-4">
          {{completeRegistrationData}}
          {{messageregistration}}
          <div class="card card-body border card-plain border-radius-lg d-flex align-items-center flex-row">
            <span class="text-bold mr-2">[{{otpprefix}}] -</span>
            <h6 class="mb-0"><input type="text" v-model="partnerJson.otpva" class="form-control"></h6>
            <button @click="sendOTP" class="btn bg-gradient-primary ml-2 mb-0 mr-2"><i class="fas fa-plus" aria-hidden="true"></i>Validate OTP</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardService from "@/services/dashboard.service";
export default {
  name: "MerchantInfo",
  props:{
    merchantid:String,
    merchantname:String,
  },
  methods:{
    showSecret(){
        this.openSecret = !this.openSecret;
        if(this.openSecret){
          this.eyeicon = 'fa-regular fa-eye-slash';
          this.secretKey = this.partnerData.partner_secret;
        }else{
          this.eyeicon = 'fa-regular fa-eye';
          this.secretKey = '********';
        }
    },
    sendOTP(){
      this.loadingregister = true;
      const data = {
        method:'registerMerchantComplete',
        partnerid:this.partnerJson.partnerId,
        otp: this.partnerJson.otpva,
        regdata: this.registrationData
      }
      DashboardService.generateVA(data).then(
          (response)=>{
            if(response.data.code==1){
              this.completeRegistrationData = response.data.data;
              this.messageregistration = response.data.message;
              this.showOtpBox = false;
              //SHOW merchant detail box request
              this.loadingregister = false;
              this.disablevagenerate = true;
            }else{
              this.completeRegistrationData = response.data.data;
              this.messageregistration = response.data.message;
              this.loadingregister = false;
            }
          }
      )
    },
    generateVA(){
      this.loadingregister = true;
      const data = {
        method:'registerMerchant',
        partnerid:this.partnerJson.partnerId
      }
      DashboardService.generateVA(data).then(
          (response)=>{
            if(response.data.code==1){
              this.registrationData = response.data.data;
              this.registrationRrror = response.data.error;
              this.otpprefix = this.registrationData.tokenPrefix;
              this.showOtpBox = true;
              this.loadingregister = false;
              this.disablevagenerate = true;
            }else{
              this.registrationRrror = response.data.error;
              this.loadingregister = false;
            }
          }
      )
    },
    toggleCollapse(){
      //this.collapsed = !this.collapsed;
      if(this.collapsed){
        this.icon= 'fa-solid fa-chevron-up';
        this.text = 'open';
      }else{
        this.icon= 'fa-solid fa-chevron-down';
        this.text = 'close';
      }
    },
    getPartnerInfo(){
      this.loading = true;
      DashboardService.getPartnerInfo(this.partnerJson).then(
          (response)=>{
            if(response.data.code==1){
              this.partnerData = response.data.data.data[0];
              if(this.partnerData.partner_config=='notconfigured'){
                this.configured = false;
              }else{
                this.configured = true;
              }
              this.loading = false;
            }else{
              this.loading = false;
            }
          }
      )
    },
  },
  data:()=>({
    icon: 'fa-solid fa-chevron-down',
    eyeicon: 'fa-regular fa-eye-slash',
    secretKey: '********',
    openSecret: false,
    showOtpBox: false,
    tampilkan: false,
    configured : true,
    collapsed: false,
    otpprefix: '',
    registrationRrror:'',
    disablevagenerate: false,
    text: 'close',
    merchantRegistrationId:'',
    messageregistration:'',
    registrationData:'',
    completeRegistrationData:'',
    imageLoading: require('@/assets/img/cube_loading.gif'),
    partnerData:[],
    loadingregister:false,
    loading: true,
    partnerJson:{
      partnerId:'',
      otpva:'',
    }
  }),
  mounted() {
    this.partnerJson.partnerId = this.merchantid;
    this.getPartnerInfo();
  }
}
</script>

<style scoped>

</style>