<template>
  <div class="row">
    <div class="col-sm-12 mt-sm-0 mt-4">
      <div class="card">
        <div class="col" >
          <div class="card-header bg-gray-200">Add Payment Gateway <button @click="doViewFormUsers"><i :class="partnerInfoIcon"></i></button> </div>
          <div class="card-body bg-gray-100" v-show="infocollapsed">
            <fieldset :disabled="loading">
              <div class="mb-3">
                <label for="form_p_name" class="form-label">Code</label>
                <input type="text" class="form-control" id="form_p_code" v-model="dataJsonInfo.form_p_code" placeholder="PG Code">
                <span class="font-weight-thin">* Gunakan huruf dan angka tanpa spasi</span>
                <span v-if="v$.dataJsonInfo.form_p_code.$error">{{ v$.dataJsonInfo.form_p_code.$errors[0].$message }}</span>
              </div>
              <div class="mb-3">
                <label for="form_p_name" class="form-label">Account Name</label>
                <input type="text" class="form-control" id="form_p_account" v-model="dataJsonInfo.form_p_account" placeholder="PG Account Name">
                <span v-if="v$.dataJsonInfo.form_p_account.$error">{{ v$.dataJsonInfo.form_p_account.$errors[0].$message }}</span>
              </div>
              <div class="mb-3">
                <label for="form_p_name" class="form-label">Name</label>
                <input type="text" class="form-control" id="form_p_name" v-model="dataJsonInfo.form_p_name" placeholder="PG Name">
                <span v-if="v$.dataJsonInfo.form_p_name.$error">{{ v$.dataJsonInfo.form_p_name.$errors[0].$message }}</span>
              </div>
              <div class="mb-3">
                <label for="form_p_name" class="form-label">Configuration</label>
                <input type="text" class="form-control" id="form_p_config" v-model="dataJsonInfo.form_p_config" placeholder="PG Configuration">
                <span v-if="v$.dataJsonInfo.form_p_config.$error">{{ v$.dataJsonInfo.form_p_config.$errors[0].$message }}</span>
              </div>
              <div class="form-group d-flex align-items-center justify-content-between">
                <span class="text-sm text-bold">Activate PG?</span>
                <div class="form-check form-switch ms-3">
                  <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault30" checked="" v-model="dataJsonInfo.form_p_active" >
                </div>
              </div>
            </fieldset>
            <span v-show="loading" class="spinner-border spinner-border-sm"></span>
            <div :class="classAlertAdd" role="alert" v-if="message.addpg">
              {{message.addpg}}
            </div>
            <div class="mb-3">
              <span v-show="loading" class="spinner-border spinner-border-sm"></span>
              <button @click="doSubmitAddPg" class="btn btn-success mr-2" :disabled="loading" type="button" >Submit</button>
              <button @click="doClearInfo" class="btn btn-info" :disabled="loading" type="button" >Clear</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardService from "@/services/dashboard.service";
import useValidate from '@vuelidate/core'
import {alpha,required,alphaNum, minLength,maxLength} from '@vuelidate/validators'
export default {
  name: "AddNewPaymentGateway",
  methods:{
    doViewFormUsers() {
      this.infocollapsed = !this.infocollapsed;
      if(this.infocollapsed){
        this.partnerInfoIcon = 'fa-solid fa-chevron-up';
      }else{
        this.partnerInfoIcon = 'fa-solid fa-chevron-down';
      }
    },
    doClearInfo(){
      this.dataJsonInfo.form_p_name='';
      this.dataJsonInfo.form_p_code='';
      this.dataJsonInfo.form_p_active='';
      this.dataJsonInfo.form_p_account='';
      this.dataJsonInfo.form_p_active='';
    },
    doSubmitAddPg(){
      this.v$.dataJsonInfo.$validate();
      if(!this.v$.dataJsonInfo.$error){
        this.loading = true;
        DashboardService.postAddPaymentGateway(this.dataJsonInfo).then(
            (response)=>{
              this.message.addpg = response.message;
              if(response.code==1){
                this.classAlertAdd = 'alert alert-success'
              }else{
                this.classAlertAdd = 'alert alert-danger'
              }
              this.loading = false;
            }
        )
      }else{
        this.loading = false;
        this.message.addusers = this.v$.dataJsonInfo.$errors[0].message;
      }
    }
  },
  data:()=>({
    v$: useValidate(),
    infocollapsed: false,
    loading: false,
    partnerInfoIcon: 'fa-solid fa-chevron-down',
    classAlertAdd : 'alert alert-primary',
    message:{
      addpg:'',
    },
    dataJsonInfo: {
      form_p_code: '',
      form_p_name: '',
      form_p_account:'',
      form_p_config: '',
      form_p_active:''
    }
  }),
  validations() {
    return {
      dataJsonInfo: {
        form_p_code: {required,alpha,minLength: minLength(5), maxLength: maxLength(50)},
        form_p_account: {required,minLength: minLength(5),maxLength: maxLength(50)},
        form_p_config: {},
        form_p_name: {required,alphaNum,minLength: minLength(5),maxLength: maxLength(50)},
        form_p_active:false
      }
    }
  },
}
</script>

<style scoped>

</style>