<template>
  <DashboardLeftMenu></DashboardLeftMenu>
  <PageLogViewer></PageLogViewer>
</template>

<script>
import DashboardLeftMenu from "@/components/DashboardLeftMenu";
import PageLogViewer from "@/views/PageLogViewer";
export default {
  name: "RouterLogViewer",
  components:{
    DashboardLeftMenu,PageLogViewer
  }
}
</script>

<style scoped>

</style>