<template>
  <main class="main-content">
    <div class="container-fluid py-4">
      <div class="row">
        <div class="col-12">
          <div class="card mb-12">
            <div class="card-header pb-0">
              <div class="row">
                <div class="col">
                    <h6>Data {{currentRouteName}}</h6>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-body p-3">
                <TableFilter f_action='payin' :f_loading=loading :f_submit="searchSubmit" :f_download=true
                 :f_partner_id=true :f_trx_id=true :f_trx_type_2=true
                  @toggle-expand="toggleExpandListener" :expand="expand"  ></TableFilter>
              </div>
              <div class="card-body p-3">
                <div class="row mt-4">
                  <EasyDataTable
                      buttons-pagination
                      alternating
                      v-model:server-options="serverOptions"
                      :server-items-length="numberOfPages"
                      :loading="loading"
                      :headers="headers"
                      :items="items"
                      :search-field="searchField"
                      :search-value="searchValue"
                  >
                    <template #item-trx_status="item">
                      <span v-if="item.trx_status=='00'" class="badge bg-success text-white text-bold">{{item.trx_status}}</span>
                      <span v-else-if="item.trx_status=='96'" class="badge bg-error text-white text-bold">{{item.trx_status}}</span>
                      <span v-else class="badge bg-warning text-white text-bold">{{item.trx_status}}</span>
                    </template>
                    <template #item-netAmount="{netAmount}">
                      <span class="text-bold">{{netAmount}}</span>
                    </template>
                    <template #item-is_settled="{is_settled}">
                      <span v-if="is_settled==1" class="badge bg-success text-bold">Yes</span>
                      <span v-else class="badge bg-gray-300 text-black text-bold">No</span>
                    </template>
                    <template #item-view="item">
                      <button  class="btn btn-primary my-3" @click="viewDetail(item)">
                          See Detail
                      </button>
                    </template>
                    <template #loading>
                      <img :src="imageLoading" style="width: 100px; height: 80px;"/>
                    </template>
                  </EasyDataTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import RequestService from "@/services/request.service";
import TableFilter from "@/views/TableFilter";
import e2payApis from "@/services/e2pay-apis";

export default {
  name: "TableOverdraftHistory",
  components:{
    TableFilter,
  },
  computed:{
    currentRouteName() {
      return this.$route.name;
    }
  },
  methods:{
    toggleExpandListener() {
      this.expand = !this.expand;
    },
    searchSubmit(dataParam){
      this.searchParam = dataParam;
      this.searchParam.trxType = 'payin';
      this.searchParam.serverOptions = this.serverOptions;

      this.fetchData();
    },
    fetchData(){
      this.loading = true;
      this.searchParam.trxType = 'payin';
      this.searchParam.serverOptions = this.serverOptions;
      RequestService.postRequest(e2payApis.GET_OVERDRAFT_HISTORY_LIST, this.searchParam).then(
          (response)=>{
            this.items = response.data.data.data;
            this.numberOfPages = response.data.data.dataCount;
            this.loading = false;
          }
      );
    }
  },
  data:()=>({
    items: [],
    imageLoading: require('@/assets/img/cube_loading.gif'),
    numberOfPages : 0,
    searchField:'',
    searchValue:'',
    loading: false,
    headers: [
      { text: "No", value: "no" , sortable: true},
      { text: "Trx ID", value: "trx_id" , sortable: true},
      { text: "Payment Datetime", value: "payment_datetime" , sortable: false},
      { text: "Merchant", value: "partner_name" , sortable: true},
      { text: "Type", value: "type" , sortable: true},
      { text: "Previous Amount", value: "prev_amount_in_string" , sortable: true},
      { text: "Payment Amount", value: "payment_amount_in_string" , sortable: true},
      { text: "Current Amount", value: "curr_amount_in_string" , sortable: true},
    ],
    searchParam:{},
    serverOptions:{
      page: 1,
      rowsPerPage: 25,
      sortBy:'',
      sortType:'',
    },
    expand: false,
  }),
  beforeMount() {
    this.fetchData();
  },
  watch:{
    serverOptions:{
      handler(){
        this.searchParam.serverOptions = this.serverOptions;
        this.fetchData(this.searchParam);
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>