<template>
  <main class="main-content">
    <div class="container">
      <div class="center">
        <div class="card">
          <div class="card-body p-3">
            <div class="row">
              <div class="col-lg-6">
                <div class="d-flex flex-column h-100">
                  <p class="mb-1 pt-2 text-bold">We are sorry</p>
                  <h5 class="font-weight-bolder">This is not the page you're looking for</h5>
                  <p class="mb-5">It appears the page you seek doesn't exist.</p>
                  <a href="/" class="text-body text-sm font-weight-bold mb-0 icon-move-right mt-auto">
                    Let's go back to home
                    <i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
                  </a>
                </div>
              </div>
              <div class="col-lg-5 ms-auto text-center mt-5 mt-lg-0">
                <div class="bg-gradient-primary border-radius-lg h-100">
                  <img src="/assets/img/shapes/waves-white.svg" class="position-absolute h-100 w-50 top-0 d-lg-block d-none" alt="waves">
                  <div class="position-relative d-flex align-items-center justify-content-center h-100">
                    <img class="w-100 position-relative z-index-2 pt-4" src="/assets/img/illustrations/rocket-white.png" alt="rocket">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "NotFound404",
  components:{
  }
}
</script>

<style scoped>
</style>