<template>
  <div class="card h-100">
    <div class="card-header pb-0">
      <h6>Holiday</h6>
      <p class="text-sm">
        <span class="font-weight-bold">List of holiday</span> this month
      </p>
    </div>
    <img :src="imageLoading" v-if="loading" class="w-10 rounded mx-auto d-block"/>
    <div class="card-body p-3" v-if="!loading">
      <div class="timeline timeline-one-side" v-for="(holyday, index) in holydaydata" :key="index">
        <div class="timeline-block mb-3">
          <span class="timeline-step"><i class="ni ni-bell-55 text-warning text-gradient"></i></span>
          <div class="timeline-content">
            <h6 class="text-dark text-sm font-weight-bold mb-0">{{holyday.holydate}}</h6>
            <p class="text-primary font-weight-bold text-xs mt-1 mb-0">{{holyday.holydesc}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardService from "@/services/dashboard.service";
export default {
  name: "HolidayData",
  methods:{
    getHoldayData(){
      this.loading = true;
      DashboardService.getHolidayData(this.dataJsonInfo).then(
          (response)=>{
            if(response.data.code==1){
              this.holydaydata = response.data.data;
              this.loading = false;
            }else{
              this.loading = false;
            }
          }
      )
    }
  },
  data:()=>({
    loading: true,
    imageLoading: require('@/assets/img/cube_loading.gif'),
    dataJsonInfo:'',
    holydaydata:[]
  }),
  mounted() {
    this.getHoldayData()
  }
}
</script>

<style scoped>

</style>