<template>
  <DashboardLeftMenu></DashboardLeftMenu>
  <TableRequestLog></TableRequestLog>
</template>

<script>
import DashboardLeftMenu from "@/components/DashboardLeftMenu";
import TableRequestLog from "@/views/TableRequestLog";
export default {
  name: "RoutePageRequestLog",
  components:{
    DashboardLeftMenu,
    TableRequestLog
  }
}
</script>

<style scoped>

</style>