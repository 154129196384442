<template>
  <div class="row">
    <div class="col-sm-12 mt-sm-0 mt-4">
      <div class="card">
        <div class="col" >
          <div class="card-header bg-gray-200">Add Partners <button @click="doViewFormPartner"><i :class="partnerInfoIcon"></i></button> </div>
            <div class="card-body bg-gray-100" v-show="partner_info_collapsed">
              <fieldset :disabled="loading_partner">
                <div class="mb-3">
                  <label for="form_p_name" class="form-label">Partner Name</label>
                  <input type="text" class="form-control" id="form_p_name" v-model="dataJsonInfo.form_p_name" placeholder="Partner Name">
                  <span v-if="v$.dataJsonInfo.form_p_name.$error">{{ v$.dataJsonInfo.form_p_name.$errors[0].$message }}</span>
                </div>
                <div class="mb-3">
                  <label for="form_p_address" class="form-label">Partner Address</label>
                  <input type="text" class="form-control" id="form_p_address" v-model="dataJsonInfo.form_p_address" placeholder="Partner Address">
                  <span v-if="v$.dataJsonInfo.form_p_address.$error">{{ v$.dataJsonInfo.form_p_address.$errors[0].$message }}</span>
                </div>
                <div class="mb-3">
                  <label for="form_p_email" class="form-label">Email address</label>
                  <input type="email" class="form-control" id="form_p_email" v-model="dataJsonInfo.form_p_email" placeholder="name@example.com">
                  <span v-if="v$.dataJsonInfo.form_p_email.$error">{{ v$.dataJsonInfo.form_p_email.$errors[0].$message }}</span>
                </div>
                <div class="mb-3">
                  <label for="form_p_phone" class="form-label">Telephone</label>
                  <input type="text" class="form-control" id="form_p_phone" v-model="dataJsonInfo.form_p_phone" placeholder="081234567890">
                  <span v-if="v$.dataJsonInfo.form_p_phone.$error">{{ v$.dataJsonInfo.form_p_phone.$errors[0].$message }}</span>
                </div>
                <div class="mb-3">
                  <label for="form_p_phone" class="form-label">VA Pooling</label>
                  <VueMultiselect
                      :multiple="false"
                      v-model="dataJsonInfo.form_p_vapooling"
                      :options="vapoolOptions"
                      :taggable="true"
                      label="name"
                      track-by="code"
                  >
                  </VueMultiselect>
                  <span v-if="v$.dataJsonInfo.form_p_vapooling.$error">{{ v$.dataJsonInfo.form_p_vapooling.$errors[0].$message }}</span>
                </div>
                <div class="mb-3">
                  <label for="form_p_ip" class="form-label">IP Address</label>
                  <VueMultiselect
                      :multiple="true"
                      v-model="dataJsonInfo.form_p_ip"
                      :options="ipOptions"
                      :taggable="true"
                      label="name"
                      track-by="code"
                      @tag="addTag"
                  >
                  </VueMultiselect>
                  <span v-if="v$.dataJsonInfo.form_p_ip.$error">{{ v$.dataJsonInfo.form_p_ip.$errors[0].$message }}</span>
                </div>
                <div class="mb-3">
                  <label for="form_p_channel" class="form-label">Channel</label>
                  <VueMultiselect
                      :multiple="true"
                      v-model="dataJsonInfo.form_p_channel"
                      :options="channelOptions"
                      :taggable="false"
                      label="name"
                      track-by="name"
                  >
                  </VueMultiselect>
                  <span v-if="v$.dataJsonInfo.form_p_channel.$error">{{ v$.dataJsonInfo.form_p_channel.$errors[0].$message }}</span>
                </div>
                <div class="form-group d-flex align-items-center justify-content-between">
                  <span class="text-sm text-bold">Activate partner?</span>
                  <div class="form-check form-switch ms-3">
                    <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault30" checked="" v-model="dataJsonInfo.form_p_active" >
                  </div>
                </div>
              </fieldset>
              <span v-show="loading_partner" class="spinner-border spinner-border-sm"></span>
              <div :class="classAlertAddPartner" role="alert" v-if="message.addpartner">
                {{message.addpartner}}
              </div>
              <div class="mb-3">
                <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                <button @click="doSubmitAddPartner" class="btn btn-success mr-2" :disabled="loading_partner" type="button" >Submit</button>
                <button @click="doClearInfo" class="btn btn-info" :disabled="loading_partner" type="button" >Clear</button>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardService from "@/services/dashboard.service";
import useValidate from '@vuelidate/core'
import {email, required, alpha, numeric,minLength,maxLength} from '@vuelidate/validators'
export default {
  name: "ClientPage",
  props: {
    datapartner: undefined,
    infocolapsed: Boolean,
  },
  components:{

  },
  methods:{
    addTag (newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
      }
      this.ipOptions.push(tag);
    },
    doViewFormPartner() {
      this.partner_info_collapsed = !this.partner_info_collapsed;
      if(this.partner_info_collapsed){
        this.partnerInfoIcon = 'fa-solid fa-chevron-up';
      }else{
        this.partnerInfoIcon = 'fa-solid fa-chevron-down';
      }
    },
    doViewFormSaldo() {
      this.partner_saldo_collapsed = !this.partner_saldo_collapsed;
      if(this.partner_saldo_collapsed){
        this.partnerBalanceIcon = 'fa-solid fa-chevron-up';
      }else{
        this.partnerBalanceIcon = 'fa-solid fa-chevron-down';
      }
    },
    doClearInfo(){
      this.dataJsonInfo.form_p_name='';
      this.dataJsonInfo.form_p_address='';
      this.dataJsonInfo.form_p_email='';
      this.dataJsonInfo.form_p_phone='';
      this.dataJsonInfo.form_p_ip='';
      this.dataJsonInfo.form_p_channel='';
      this.dataJsonInfo.form_p_active='';
      this.dataJsonInfo.form_p_vapooling='';
    },
    doClearBalance(){
      this.dataJsonBalance.form_pb_name = '';
    },
    getVaPooling(){
      this.loading = true;
      DashboardService.getVaPoolingListCode(this.dataJsonInfo).then(
          (response)=>{
            if(response.data.code==1){
              this.vapoolOptions = response.data.data;
              this.loading = false;
            }else{
              this.loading = false;
            }
          }
      )
    },
    getPaymentListCode(){
      this.loading = true;
      DashboardService.getPaymentListCode(this.dataJsonInfo).then(
          (response)=>{
            if(response.data.code==1){
              this.channelOptions = response.data.data;
              this.loading = false;
            }else{
              this.loading = false;
            }
          }
      )
    },
    doSubmitAddPartner(){
      this.v$.dataJsonInfo.$validate();
      if(!this.v$.dataJsonInfo.$error){
        this.loading_partner = true;
        DashboardService.postAddPatner(this.dataJsonInfo).then(
            (response)=>{
              this.message.addpartner = response.message;
              if(response.code==1){
                this.classAlertAddPartner = 'alert alert-success'
              }else{
                this.classAlertAddPartner = 'alert alert-danger'
              }
              this.loading_partner = false;
            }
        )
      }else{
        this.loading_partner = false;
        this.message.addpartner = this.v$.dataJsonInfo.$errors[0].message;
      }
    },
    doSubmitAddPartnerBalance(){
      this.v$.dataJsonBalance.$validate();
      if(!this.v$.dataJsonBalance.$error){
        this.loading_balance = true;
        DashboardService.postAddBalance(this.dataJsonBalance).then(
            (response)=>{
              this.message.addbalance = response.message;
              this.loading_balance = false;
              if(response.code==1){
                this.classAlertAddBalance = 'alert alert-success'
              }else{
                this.classAlertAddBalance = 'alert alert-danger'
              }
            }
        )
      }else{
        this.loading_balance = false;
        this.message.addbalance = this.v$.dataJsonBalance.$errors[0].message;
      }
    },
    myChangeEvent(val){
      console.log(val);
    },
    mySelectEvent({id, text}){
      console.log({id, text})
    }
  },
  data:()=>({
    v$: useValidate(),
    message:{
      addpartner:'',
      addbalance:'',
    },
    form:'',
    control:'',
    loading: false,
    loading_partner: false,
    loading_balance: false,
    collapsed: false,
    partner_info_collapsed: false,
    partner_saldo_collapsed: false,
    partnerBalanceIcon: 'fa-solid fa-chevron-down',
    partnerInfoIcon: 'fa-solid fa-chevron-down',
    filterIcon: 'fa-solid fa-chevron-down',
    balanceType:'',
    select2settingIp:{ dropdownAutoWidth: false, tags:true, multiple:true,width: '100%'},
    select2settingP:{ dropdownAutoWidth: false,multiple:true,width: '100%'},
    select2setting:{ dropdownAutoWidth: false,multiple:false,width: '100%'},
    ipOptions: [],
    classAlertAddPartner : 'alert alert-primary',
    classAlertAddBalance : 'alert alert-primary',
    balanceOptions: [{code:1,name:'Biller'},{code:2,name:'Disbursment'}],
    channelOptions: [{code:25,name:'Dana Wallet'},{code:26,name:'Bank Mega'}],
    vapoolOptions: [{code:0,name:'Test'}],
    dataJsonBalance: {
      form_pb_name: '',
      form_pb_type: '',
      form_pb_amount:0,
    },
    dataJsonInfo: {
      form_p_name: '',
      form_p_address: '',
      form_p_email: '',
      form_p_phone: '',
      form_p_ip: '',
      form_p_channel: '',
      form_p_active:false,
      form_p_vapooling:''
    }
  }),
  validations() {
    return {
      dataJsonInfo: {
        form_p_name: {required,minLength: minLength(5)},
        form_p_address: {required,maxLength:maxLength(150)},
        form_p_email: {required, email},
        form_p_phone: {required},
        form_p_ip: {required},
        form_p_channel: {required},
        form_p_active: {},
        form_p_vapooling:{}
      },
      dataJsonBalance: {
        form_pb_name: {required, alpha},
        form_pb_type: {required},
        form_pb_amount:{required,numeric},
      }
    }
  },
  mounted() {
    this.getPaymentListCode();
    this.getVaPooling();
  },
  unmounted() {
  },
  watch:{
    infocolapsed: {
      immediate: false,
      deep: true,
      handler() {
          this.partner_info_collapsed = this.infocolapsed;
      }
    },
    datapartner:{
      immediate: false,
      deep: true,
      handler(){
        this.dataJsonInfo.form_p_name = this.datapartner.partner_name;
        this.dataJsonInfo.form_p_address = this.datapartner.partner_address;
        this.dataJsonInfo.form_p_email = this.datapartner.partner_email;
        this.dataJsonInfo.form_p_phone = this.datapartner.partner_telp;
        this.dataJsonInfo.form_p_ip = this.datapartner.partner_allow_ip;
        this.dataJsonInfo.form_p_channel = this.datapartner.partner_allow_channel;
        this.dataJsonInfo.form_p_active = this.datapartner.partner_status==1? true:false;
      }
    }
  }
}
</script>

<style scoped>

</style>