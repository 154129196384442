<template>
  <DashboardLeftMenu></DashboardLeftMenu>
  <TableProductPrice></TableProductPrice>
</template>

<script>
import DashboardLeftMenu from "@/components/DashboardLeftMenu";
import TableProductPrice from "@/views/TableProductPrice";
export default {
  name: "RouteProductPrice",
  components:{
    DashboardLeftMenu,
    TableProductPrice
  }
}
</script>

<style scoped>

</style>