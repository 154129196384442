<template>
    <main class="main-content">
       <div class="container">
           <div class="center">
               <div class="card mt-12">
                   <div class="card-body text-center">
                        <div style="border-radius:200px; height:200px; width:200px; background: #F8FAF5; margin:0 auto;">
                            <i class="crossmark">X</i>
                        </div>
                        <h1 :class="'failed-title'">Failed</h1> 
                        <p class="subtitle">{{ getMessage }}</p>
                        <p><i>You'll be redirected to merchant site shortly in {{ remainingTime }}</i> </p>
                        <p class="text-danger"><strong>DO NOT CLOSE THIS WINDOW</strong></p>
                    </div>
               </div>
           </div>
       </div>
   </main>
 </template>
 
 <script>
 
 export default {
   name: "PaymentFailedPage",
   mounted() {
       if (this.$route.query.redirectUrl == undefined) {
           this.$router.go(-1);
       } else {
        this.status = this.$route.query.status;
        this.timer = setInterval( () => {
            if (this.remainingTime > 0) {
                this.remainingTime--
            } else {
                clearInterval(this.timer);
                window.location.replace(this.$route.query.redirectUrl);
            }
        }, 1000 )
       }
   },
   components:{
       
   },
   computed: {
        getMessage: function() {
            let msg = "";
            switch(this.status) {
                case '1':
                    msg = "Transaction not found";
                    break;
                case '2':
                console.log('his.status, msg');
                    msg = "Transaction expired";
                    break;
                default:
                    // code block
            }
            return msg;
        }
   },
   methods:{
   },
   data:()=>({
       status: false,
       loading: false,
       timer: null,
       remainingTime: 5
 }),
 }
 </script>
 
 <style scoped>
    .checkmark {
        color: #9ABC66;
        font-size: 100px;
        line-height: 200px;
        margin-left:-15px;
      }
      .crossmark {
        color: #ef1616;
        font-size: 100px;
        line-height: 200px;
        margin-left:-15px;
      }
      .subtitle {
          color: #404F5E;
          font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
          font-size:20px;
          margin: 0;
        }
   .success-title {
        color: #88B04B;
        font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
        font-weight: 900;
        font-size: 40px;
        margin-bottom: 10px;
   }
   .failed-title {
        color: #ef1616;
        font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
        font-weight: 900;
        font-size: 40px;
        margin-bottom: 10px;
   }
 </style>