<template>
  <DashboardLeftMenu></DashboardLeftMenu>
  <DashboardRightContent></DashboardRightContent>
</template>

<script>
import DashboardRightContent from "@/components/DashboardRightContent";
import DashboardLeftMenu from "@/components/DashboardLeftMenu";
export default {
  name: "HomePerjuangan",
  components:{
    DashboardLeftMenu,
    DashboardRightContent
  },
  props:{
    msg: String
  },
}
</script>

<style scoped>

</style>