<template>
  <main class="main-content">
    <NavigationTop :pages=currentRouteName :subpages=currentRouteName></NavigationTop>
    <div class="container-fluid py-4">
      <modal-payin-detail :title="dialogTitle" :openDialog="payinDialog" @close-dialog="closeDialog" 
        :f_submitdownload="downloadFromDialog"
        :list="dialogList" :canDownload=true></modal-payin-detail>
      <div class="row">
        <div class="col-12">
          <div class="card mb-12">
            <div class="card-header pb-0">
              <h6>Data {{currentRouteName}}</h6>
            </div>
            <div class="card">
              <div class="card-body p-3">
                <TableFilter f_action='payin' :f_loading=loading :f_submit="searchSubmit" :f_download=true
                 :f_partner_code=true :f_amount=true :f_date_range=true :f_refno=true :f_sourcerefno=true
                   :f_channel=true :can_show_chart=true :f_submitdownload="downloadSubmit"
                  @toggle-expand="toggleExpandListener" :expand="expand" :balanceData="balanceData" ></TableFilter>
              </div>
              <div class="card-body p-3">
                <div class="row mt-4">
                  <a :href="filedownload" v-if="filedownload" class="btn btn-behance mr-2" type="button"><i class="fa fa-solid fa-file-excel"></i> Dowload</a>
                  <EasyDataTable
                      show-index
                      buttons-pagination
                      alternating
                      v-model:server-options="serverOptions"
                      :server-items-length="numberOfPages"
                      :loading="loading"
                      :headers="headers"
                      :items="transactions"
                      :search-field="searchField"
                      :search-value="searchValue"
                  >
                    <template #expand="item">
                      <div style="padding: 15px">
                        {{item.product_name}}
                      </div>
                    </template>
                    <template #item-trx_status="item">
                      <span v-if="item.trx_status=='00'" class="badge bg-success text-white text-bold">{{item.trx_status}}</span>
                      <span v-else-if="item.trx_status=='96'" class="badge bg-error text-white text-bold">{{item.trx_status}}</span>
                      <span v-else class="badge bg-warning text-white text-bold">{{item.trx_status}}</span>
                    </template>
                    <template #item-netAmount="{netAmount}">
                      <span class="text-bold">{{netAmount}}</span>
                    </template>
                    <template #item-is_settled="{is_settled}">
                      <span v-if="is_settled==1" class="badge bg-success text-bold">Yes</span>
                      <span v-else class="badge bg-gray-300 text-black text-bold">No</span>
                    </template>
                    <template #item-view="item">
                      <button  class="btn btn-primary my-3" data-bs-toggle="modal" data-bs-target="#payinModalId" @click="viewPrice(item)">
                          See Detail
                      </button>
                    </template>
                    <template #loading>
                      <img :src="imageLoading" style="width: 100px; height: 80px;"/>
                    </template>
                  </EasyDataTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import RequestService from "@/services/request.service";
import NavigationTop from "@/components/NavigationTop";
import TableFilter from "@/views/TableFilter";
import e2payApis from "@/services/e2pay-apis";
import ModalPayinDetail from "@/views/ModalPayinDetail.vue";
import DashboardService from "@/services/dashboard.service";

export default {
  name: "TableFailedTransaction",
  components:{
    NavigationTop,
    TableFilter,
    ModalPayinDetail,
  },
  computed:{
    currentRouteName() {
      return this.$route.name;
    }
  },
  methods:{
    toggleExpandListener() {
      this.expand = !this.expand;
    },
    downloadSubmit(downloadtype, dataParam){
      this.loading = true;
      if (dataParam !== undefined) {
        this.searchParam = dataParam;
        this.searchParam.download_type = downloadtype;
        this.searchParam.download = true;
        this.searchParam.trxType = 'payin';
        this.searchParam.chartType = 'payin';
        this.searchParam.serverOptions = this.serverOptions;
        RequestService.postRequest(e2payApis.GET_FAILED_PAYIN, this.searchParam).then(
            (response)=>{
              this.loading = false;
              window.open(response.data.data.downloadLink, '_blank');
            }
        );
      }
    },
    searchSubmit(dataParam){
      this.searchParam = dataParam;
      this.searchParam.trxType = 'payin';
      this.searchParam.serverOptions = this.serverOptions;

      this.fetchData();
    },
    fetchData(){
      this.loading = true;
      this.searchParam.trxType = 'payin';
      this.searchParam.serverOptions = this.serverOptions;
      RequestService.postRequest(e2payApis.GET_FAILED_PAYIN, this.searchParam).then(
          (response)=>{
            this.transactions = response.data.data.data;
            this.numberOfPages = response.data.data.dataCount;
            this.filedownload = response.data.data.downloadLink || '';
            if(!this.filedownload){
              this.downloadSubmit();
            }
            this.loading = false;
          }
      );
    },
    showReceivableDialog() {
      this.loading = true;
      this.dialogTitle = "Receivable Detail";
      this.searchParam.balanceType = 'receivables';
      this.searchParam.trxType = 'receivables';
      this.searchParam.chartType = 'receivables';
      this.searchParam.serverOptions = this.serverOptions;
      RequestService.postRequest(e2payApis.GET_PAYIN_BALANCE, this.searchParam).then(
          (response)=>{
            console.log('resp', response.data.data.data);
            this.loading = false;
            this.dialogList = response.data.data.data;
            this.payinDialog = true;
          }
      );
    },
    showSettlementDialog() {
      this.loading = true;
      this.dialogTitle = "Ready to Settle Detail";
      this.searchParam.balanceType = 'ready-to-settle';
      this.searchParam.trxType = 'ready-to-settle';
      this.searchParam.chartType = 'ready-to-settle';
      this.searchParam.serverOptions = this.serverOptions;
      RequestService.postRequest(e2payApis.GET_PAYIN_BALANCE, this.searchParam).then(
          (response)=>{
            console.log('resp', response.data.data.data);
            this.loading = false;
            this.dialogList = response.data.data.data;
            this.payinDialog = true;
          }
      );
    },
    downloadFromDialog(downloadtype){
      this.loading = true;
      if (downloadtype !== undefined) {
        this.searchParam.download_type = downloadtype;
        this.searchParam.download = true;
        this.searchParam.serverOptions = this.serverOptions;
        RequestService.postRequest(e2payApis.GET_LINK_DOWNLOAD, this.searchParam).then(
            (response)=>{
              this.loading = false;
              this.payinDialog = false;
              window.open(response.data.data.downloadLink, '_blank');
            }
        );
      }
    },
    closeDialog() {
      this.payinDialog = false;
    }
  },
  data:()=>({
    imageLoading: require('@/assets/img/cube_loading.gif'),
    balanceData: [],
    filedownload:'',
    transactions: [],
    content:"",
    numberOfPages : 0,
    searchField:'refNo',
    searchValue:'',
    loading: false,
    payinDialog: false,
    dialogList: [],
    dialogTitle: "",
    settle: "0",
    receivables: "0",
    headers: [],
    searchParam:{},
    serverOptions:{
      page: 1,
      rowsPerPage: 25,
      sortBy:'',
      sortType:'',
    },
    expand: false,
    chartData:{
      labels: [ "2023-08-01", "2023-08-09", "2023-08-10", "2023-08-19", "2023-08-21" ],
      datasets: [
        {
          label: "Payout",
          backgroundColor: "#a3f879",
          borderColor: "#a3f879",
          data: [ 0, 1, 3, 0, 0 ],
          tension: 0.1
        }
      ]
    },
    tooltipMessage: " This amount will be transferred automatically to payout balance (time exactly for auto transfer ?)" +
     "except weekend and public holiday. If you wish to stop it or wants to split please click link on label Ready to Settle or" +
     "go to Settlement page",
  }),
  beforeMount() {
    this.fetchData();
    let headersPre = [
      { text: "Transaction Date", value: "trx_datetime" , sortable: true},
      { text: "Merchant Name", value: "partner_name" , sortable: true},
      { text: "Transaction RefNo", value: "refNo" , sortable: true},
      { text: "Merchant RefNo", value: "source_refno" , sortable: true},
      { text: "Issuer RefNo", value: "pg_trxid" , sortable: true},
      { text: "RC", value: "trx_status" , sortable: true},
      { text: "Status", value: "sts_desc" , sortable: true},
      { text: "Currency", value: "currency" },
      { text: "Channel", value: "product_name" , sortable: true},
      { text: "Transaction Amount (Rp.)", value: "amount" , sortable: true},
    ];
    let headersMerchant = [
      { text: "Issuer FFee (Rp.)", value: "supplier_ffee" , sortable: true},
      { text: "Issuer SFee (Rp.)", value: "supplier_sfee" , sortable: true},
      { text: "Referral FFee (Rp.)", value: "referral_ffee" , sortable: true},
      { text: "Referral SFee (Rp.)", value: "referral_sfee" , sortable: true},
      { text: "Toppay FFee (Rp.)", value: "toppay_ffee" , sortable: true},
      { text: "Toppay SFee (Rp.)", value: "toppay_sfee" , sortable: true},
    ];
    let headersReferral = [
      { text: "Referral Fee (Rp.)", value: "referralFee" , sortable: true},
      { text: "Toppay Fee (Rp.)", value: "toppayFee" , sortable: true},
    ];
    let headersPost = [
    { text: "Charged Amount (Rp.)", value: "feeAmount" },
      { text: "Settlement Amount (Rp.)", value: "netAmount" },
      { text: "Settled", value: "is_settled" },
      { text: "Settlement Days", value: "settlement_time" },
      // { text: "See Detail", value: "view" , sortable: false},
    ];
    let roleId = DashboardService.getUserRoleId();
    console.log('role', roleId);
    switch (roleId) {
      case 2:
        this.headers = headersPre.concat(headersReferral).concat(headersPost);
        break;
      case 3:
        this.headers = headersPre.concat(headersPost);
        break;
    
      default:
        headersPre.splice(headersPre.length-1, 0, { text: "Issuer", value: "pg_id_desc" , sortable: true});
        this.headers = headersPre.concat(headersMerchant).concat(headersReferral).concat(headersPost);
        break;
    }
  },
  watch:{
    serverOptions:{
      handler(){
        this.searchParam.serverOptions = this.serverOptions;
        this.fetchData(this.searchParam);
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>