<template>
  <main class="main-content">
    <NavigationTop :pages=currentRouteName :subpages=currentRouteName></NavigationTop>
    <div class="container-fluid py-4">
      <div class="row">
        <div class="col-md-7 mt-4">
          <div class="card">
            <div class="card-header pb-0 px-3">
              <h6 class="mb-0">Messages</h6>
            </div>
            <div class="card-body pt-4 p-3">
              <ul class="list-group">
                <li v-for="(message,index) in this.$store.state.messagesList" :key="index" class="list-group-item border-0 d-flex p-4 mb-2 bg-gray-100 border-radius-lg">
                  <div class="d-flex flex-column">
                    <h6 class="mb-3 text-sm"><strong>{{message.msg_subject}}</strong> from {{message.msg_from}}</h6>
                    <span class="mb-2 text-xs">Message Date: <span class="text-dark font-weight-bold ms-sm-2">{{message.msg_time}}</span></span>
                    <span class="mb-2 text-xs">Message: <span class="text-dark ms-sm-2 font-weight-bold">{{message.msg_txt}}</span></span>
                  </div>
                  <div class="ms-auto text-end">
                    <router-link :to="{path:'/messages/read/'+message.msg_to+'/'+message.id_message}" class="btn btn-link text-dark px-3 mb-0"><i class="fas fa-eye text-dark me-2" aria-hidden="true"></i>View</router-link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-5 mt-4">
          <div class="card h-100 mb-4">
            <div class="card-header pb-0 px-3">
              <div class="row">
                <div class="col-md-6">
                  <h6 class="mb-0">Today Last Transaction's</h6>
                </div>
                <div class="col-md-6 d-flex justify-content-end align-items-center">
                  <i class="far fa-calendar-alt me-2"></i>
                  <small>{{trxDate}}</small>
                </div>
              </div>
            </div>
            <div class="card-body pt-4 p-3 text-center" v-if="loading" >
              <img :src="imageLoading" width="100" height="100">
            </div>
            <div class="card-body pt-4 p-3" v-else>
              <h6 class="text-uppercase text-body text-xs font-weight-bolder mb-3">PayIn</h6>
              <ul class="list-group">
                <li v-for="(payin,index) in this.lastTransactionList.payin" :key="index" class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                  <div class="d-flex align-items-center">
                    <button class="btn btn-icon-only btn-rounded mb-0 me-3 btn-sm d-flex align-items-center justify-content-center" :class="payin.trx_status=='00'? 'btn-outline-success':'btn-outline-info'">{{payin.trx_status}}</button>
                    <div class="d-flex flex-column">
                      <h6 class="mb-1 text-dark text-sm">{{payin.product_name}}</h6>
                      <span class="text-xs">RefNo : {{payin.source_refno}}</span>
                      <span class="text-xs">Merchant Code : {{payin.partner_code}}</span>
                    </div>
                  </div>
                  <div class="d-flex align-items-center text-danger text-gradient text-sm font-weight-bold">
                    {{payin.amount}}
                  </div>
                </li>
              </ul>
              <h6 class="text-uppercase text-body text-xs font-weight-bolder my-3">PayOut</h6>
              <ul class="list-group">
                <li v-for="(payout,index) in this.lastTransactionList.payout" :key="index" class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                  <div class="d-flex align-items-center">
                    <button class="btn btn-icon-only btn-rounded mb-0 me-3 btn-sm d-flex align-items-center justify-content-center" :class="payout.trx_status=='00'? 'btn-outline-success':'btn-outline-info'">{{payout.trx_status}}</button>
                    <div class="d-flex flex-column">
                      <h6 class="mb-1 text-dark text-sm">{{payout.product_name}}</h6>
                      <span class="text-xs">RefNo : {{payout.source_refno}}</span>
                      <span class="text-xs">Merchant Code : {{payout.partner_code}}</span>
                    </div>
                  </div>
                  <div class="d-flex align-items-center text-danger text-gradient text-sm font-weight-bold">
                    {{payout.amount}}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import DashboardService from "@/services/dashboard.service";
import NavigationTop from "@/components/NavigationTop";
export default {
  name: "MessageNotification",
  components:{
    NavigationTop,
  },
  methods:{
    getLastTransaction(){
      this.loading = true;
      DashboardService.getLastTransactionInfo(this.dataJson).then(
          (response)=>{
            if(response.data.code==1){
              this.lastTransactionList = response.data.data.lasttrx;
              this.trxDate = this.lastTransactionList.date;
              this.loading = false;
            }else{
              this.loading = false;
            }
          }
      )
    },
  },
  computed:{
    currentRouteName() {
      return this.$route.name;
    }
  },
  data:()=>({
    imageLoading: require('@/assets/img/cube_loading.gif'),
    loading: true,
    lastTransactionList:[],
    trxDate: '-',
  }),
  mounted() {
    this.getLastTransaction();
  }
}
</script>

<style scoped>

</style>