<template>
  <span v-if="loading" class="spinner-border spinner-border-sm text-info"></span>
  <div v-else>
    <div class="card card-frame" >
      <div class="card-header">
        <h6 class="font-weight-bolder mb-0">Check Transaction Form</h6>
      </div>
      <div class="card-body"> 
          <div class="row" v-if="isError"> 
              <div class="col"> 
                  <div class="alert alert-danger alert-dismissible fade show" role="alert">
                      <span class="alert-text text-white"><strong>Error!</strong> {{errorMsg}}</span>
                      <button class="btn-close" data-bs-dismiss="alert" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                      </button>
                  </div>
              </div>
          </div>
          <form>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group" >
                      <label class="form-control-label" :class="{'is-invalid' : v$.form.trxType.$error}">Transaction Type</label>
                      <VueMultiselect
                          :multiple="false"
                          :class="{'is-invalid' : v$.form.trxType.$error}"
                          v-model="form.trxType"
                          :options="refTrxType"
                          :disabled="isDisabledNst"
                          label="name"
                          track-by="code"
                          placeholder="Pick Transaction Type"
                      >
                      </VueMultiselect>
                      <span class="invalid-feedback" v-if="v$.form.trxType.$error">{{ v$.form.trxType.$errors[0].$message }}</span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="form-control-label">Merchant Ref No</label>
                    <input type="text" class="form-control" :class="{'is-invalid' : v$.form.listOfMerchantRefNo.$error}" v-model="form.listOfMerchantRefNo" >
                    <span class="invalid-feedback" v-if="v$.form.listOfMerchantRefNo.$error">{{ v$.form.listOfMerchantRefNo.$errors[0].$message }}</span>
                  </div>
                </div>
              </div>
          </form>
          <div class="row mt-2">
              <div class="col-12 col-md-12"> 
                  <button @click="next()" class="btn btn-success mr-2" :disabled="loading" >Check</button>
              </div>
          </div>
      </div>
    </div>
    <div class="response-content" v-if="isShowResponse">
      <div class="card card-frame mt-3" 
        v-for="(item, index) in responseForms"
        :key="index">
        <div class="card-header">
          <h6 class="font-weight-bolder mb-0">{{ getTrxName(index) }}</h6>
        </div>
        <div class="card-body"> 
          <div class=" p-3">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="mb-3 row">
                  <label for="staticEmail" class="col-4 col-form-label">Transaction Ref No</label>
                  <div class="col-7">
                      <input type="text" readonly class="form-control-plaintext"  :value="item.toppay_refno">
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="mb-3 row">
                  <label for="staticEmail" class="col-4 col-form-label">Merchant Ref No</label>
                  <div class="col-7">
                      <input type="text" readonly class="form-control-plaintext"  :value="item.merchant_refno">
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="mb-3 row">
                  <label for="staticEmail" class="col-4 col-form-label">Issuer Transaction ID</label>
                  <div class="col-7">
                      <input type="text" readonly class="form-control-plaintext"  :value="item.issuer_trx_id">
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="mb-3 row">
                  <label for="staticEmail" class="col-4 col-form-label">Timestamp</label>
                  <div class="col-7">
                      <input type="text" readonly class="form-control-plaintext"  :value="item.transaction_timestamp">
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="mb-3 row">
                  <label for="staticEmail" class="col-4 col-form-label">Status</label>
                  <div class="col-7">
                      <input type="text" readonly class="form-control-plaintext"  :value="item.status">
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-if="responseTrxType == 'PayOut'">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="mb-3 row">
                  <label for="staticEmail" class="col-4 col-form-label">Issuer Response</label>
                  <div class="col-7">
                      <input type="text" readonly class="form-control-plaintext"  :value="getIssuerResponse(item.issuer_response, item.status)">
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="mb-3 row">
                  <label for="staticEmail" class="col-4 col-form-label">Message</label>
                  <div class="col-7">
                      <input type="text" readonly class="form-control-plaintext"  :value="item.message">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> 
      </div>
    </div>
  </div>
</template>
<script>
import useValidate from '@vuelidate/core'
import RequestService from "@/services/request.service";
import e2payApis from "@/services/e2pay-apis";
import useSimulatorFormStore from "@/store/simulator.form";
import {required} from '@vuelidate/validators'

export default {
  name: "SimulatorTransactionCheckFormPage1",
  mounted() {
    this.fetchData();
    if(this.$route.query.errorMsg) {
      this.isError = true;
      this.errorMsg = this.$route.query.errorMsg;
    }
  },
  methods:{
    getTrxName(index) {
      return this.responseTrxType == 'PayIn' ? "Pay In Transaction " : "Pay Out Transaction #"  +(index+1);
    },
    getIssuerResponse(issuerResponse, status) {
      let resp = "Succeed"
      if (issuerResponse.errors !== undefined) {
        resp = "";
        issuerResponse.errors.forEach(error => {
          resp += error + " ";
        });
      } else if (status == null || status == "") {
        resp = "";
      }

      return resp;
    },
    fetchData(){
      this.loading = false;
    },
    next() {
      this.v$.form.$validate();
      if(!this.v$.form.$error){
        this.loading = true;
        this.isShowResponse = false;
        RequestService.postRequest(e2payApis.GET_CHECK_TRANSACTION  + this.form.trxType.code + "/" + this.form.listOfMerchantRefNo, []).then(
          (response)=>{
            if(response.data != null){
              this.loading = false;
              if (response.data.code != '00') {
                this.isError = true;
                this.errorMsg = response.data.error;
              } else {
                this.isError = false;
                this.isShowResponse = true;
                this.responseForms = response.data.data;
                this.responseTrxType = this.form.trxType.code;
                // this.responseForm.title = this.form.trxType.name;
                // this.responseForm.toppay_refno = response.data.data.toppay_refno;
                // this.responseForm.merchant_refno = response.data.data.merchant_refno;
                // this.responseForm.issuer_trx_id = response.data.data.issuer_trx_id;
                // this.responseForm.transaction_timestamp = response.data.data.transaction_timestamp;
                // this.responseForm.status = response.data.data.status;
                // this.responseForm.message = response.data.data.message;
              }
            }else{
                this.loading = false;
                this.isError = true;
                this.errorMsg = "Callback Not Found";
            }
          }
        )
      }
    }
  },
  data:()=>({
        v$: useValidate(),
        simulatorForm: useSimulatorFormStore(),
        loading: true,
        registerMessage:'',
        isError: false,
        errorMsg: '',
        status: '',
        refTrxType: [{code: "PayIn", name:"Pay In"}, {code: "PayOut", name:"Pay Out"}],
        isShowResponse: false,
        callbackResponses: [],
        isDisabledNst: false,
        responseTrxType: "",
        form: {
          trxType: '',
          listOfMerchantRefNo: '',
          Signature: '0r2iur4saIcECrvV0t/8cqO0cYo='
        },
        // responseForm: {
        //   title: "",
        //   toppay_refno: "",
        //   merchant_refno: "",
        //   issuer_trx_id: "",
        //   transaction_timestamp: "",
        //   status: "",
        //   message: "",
        // },
        responseForms: []
  }),
  validations() {
    return {
      form: {
        listOfMerchantRefNo:{required},
        trxType:{required},
      }
    }
  },
  watch: {
  }
}
</script>
<style>
  .is-invalid .multiselect__tags {
    border-color: #fd5c70 !important;
    padding-right: unset;
    /* background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23fd5c70' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23fd5c70' stroke='none'/%3e%3c/svg%3e);
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 1rem 1rem; */
  }
</style>