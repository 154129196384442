<template>
  <DashboardLeftMenu></DashboardLeftMenu>
  <TableBalanceHistory :ballType="'disbursment'"></TableBalanceHistory>
</template>

<script>
import DashboardLeftMenu from "@/components/DashboardLeftMenu";
import TableBalanceHistory from "@/views/TableBalanceHistory";
export default {
  name: "RoutePageBalanceDisbursment",
  props:{

  },
  components:{
    TableBalanceHistory,
    DashboardLeftMenu,
  }
}
</script>

<style scoped>

</style>