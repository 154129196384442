<template>
  <DashboardLeftMenu></DashboardLeftMenu>
  <TableTransaction></TableTransaction>
</template>

<script>
import DashboardLeftMenu from "@/components/DashboardLeftMenu";
import TableTransaction from "@/views/TableTransaksi";

export default {
  name: "RouterPageTransaction",
  components:{
    TableTransaction,
    DashboardLeftMenu,
  }
}
</script>

<style scoped>

</style>