<template>
  <div v-if="loading" class="col-md-6">
    <div class="text-center">
      <img width="10" class="img-fluid"  :src="imageLoading" style="width: 80px; height: 50px;"/>
    </div>
  </div>
  <Line v-else
      id="my-chart-id"
      :options="chartOptions"
      :data="chartdata"
      class="col-md-6"
  ></Line>
</template>

<script>
import {Line} from 'vue-chartjs'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
ChartJS.register(  CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend)
export default {
  name: "TransactionChart",
  components:{
    Line
  },
  props:{
    chartdata:Object,
    loading: Boolean,
    chartTitle: String,
  },
  methods:{

  },
  data() {
    return{
      imageLoading: require('@/assets/img/cube_loading.gif'),
      chartData2: {
        labels: [ "2023-08-01", "2023-08-09", "2023-08-10", "2023-08-19", "2023-08-21" ],
        datasets: [
            {
              label: "Payin",
              backgroundColor: "#f87979",
              borderColor: "#f87979",
              data: [ 4, 0, 0, 2, 1 ],
              tension: 0.1
            },
          {
            label: "Payout",
            backgroundColor: "#a3f879",
            borderColor: "#a3f879",
            data: [ 0, 1, 3, 0, 0 ],
            tension: 0.1
          }
          ]
      },
      chartData1: {
        labels: [ 'January', 'February', 'March', 'April', 'Mei', 'Juni', 'Juli' ],
        datasets: [
            {
              label: 'Payout',
              backgroundColor: '#f87979',
              borderColor: '#f87979',
              data: [40, 39, 10, 40, 39, 80, 40],
              tension: 0.1
            },
          {
            label: 'Payin',
            borderColor: '#a3f879',
            backgroundColor: '#a3f879',
            data: [10, 59, 20, 50, 59, 90, 60],
            tension: 0.1
          }

        ]
      },
      chartOptions: {
        responsive: true,
        plugins:{
          title: {
            display: true,
            text: this.chartTitle
          },
          legend: {
            position: "bottom",
            align: "middle"
          },
        }
      }
    }
  }
}
</script>

<style scoped>

</style>