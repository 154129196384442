<template>
  <main class="main-content">
    <NavigationTop :pages=currentRouteName :subpages=currentRouteName></NavigationTop>
    <div class="container-fluid py-4">
      <vue-finder id='my_vuefinder' :url=fileurl></vue-finder>
    </div>
  </main>
</template>

<script>
import NavigationTop from "@/components/NavigationTop";
import DashboardService from "@/services/dashboard.service";
export default {
  name: "PageLogViewer",
  components:{
    NavigationTop,
  },
  data:()=>({
    fileurl: DashboardService.API_URL
  }),
  computed:{
    currentRouteName() {
      return this.$route.name;
    }
  },
  beforeMount() {
    this.fileurl = DashboardService.getFileBrowserUrl();
  }
}
</script>

<style scoped>

</style>