<template>
    <div class="card card-frame">
        <div class="card-header">
            <!-- <button class="btn btn-icon btn-3 btn-outline-primary mt-2" @click="$router.go(-1)">
                <span class="btn-inner--icon"><i class="fa fa-arrow-left"></i></span>
            </button> -->
            Result Payin Simulator
        </div>
        <div class="card-body"> 
          <form>
            <div class="row">
                <div class="col-12">
                    <h6 class="bg-success" v-if="form.isSuccess == 1">Payment Success !</h6>
                    <h6 class="bg-danger" v-else>Payment Failed !</h6>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-md-12">
                    <p v-if="form.isSuccess == 1">Your payment to {{form.PaymentId.product_name}} with a value of {{getAmount}} has been successful.</p>
                    <p v-else>Your payment to {{form.PaymentId.product_name}} with a value of {{getAmount}} has been failed.</p>
                    <p>Please close this page.</p>
                </div>
            </div>
          </form>
            <div class="row mt-2">
              <div class="col-12 col-md-12"> 
                  <button @click="returnToMain" class="btn btn-success mr-2" :disabled="loading" >Close</button>
              </div>
            </div>
        </div>
    </div>
</template>
<script>
import useValidate from '@vuelidate/core'
import {formatNumber} from "@/services/number-format"
import useSimulatorFormStore from "@/store/simulator.form";

export default {
  name: "SimulatorPayinNewResult",
  props:{
    // paramModel: Object,
    // collapsed: Boolean,
  },
  components: {
    
  },
  mounted() {
    if (this.simulatorForm.form == null) {
        this.$router.go(-1);
    } else {
        this.form = this.simulatorForm.form;
    }
  },
  computed: {
    getAmount: function(){
        return "Rp." + formatNumber(this.form.Amount + this.form.AdminFee, 0) + ",-";
    }
  },
  methods:{
    returnToMain() {
      this.$router.push({ name: 'Simulator Payin Page 1'});
    }
  },
  data:()=>({
        v$: useValidate(),
        simulatorForm: useSimulatorFormStore(),
        loading: false,
        form:{
          PaymentId: {
              product_name: ""
          },
          isSuccess: 0
        }
    })
}
</script>