<template>
  <main class="main-content">
    <div class="container-fluid py-4">
      <modal-overdraft-detail  :openDialog="overdraftDialog" @close-dialog="closeDialog" 
        :formDetail="selectedForm" ></modal-overdraft-detail>
      <div class="row">
        <div class="col-12">
          <div class="card mb-12">
            <div class="card-header pb-0">
              <div class="row">
                <div class="col">
                    <h6>Data {{currentRouteName}}</h6>
                </div>
                <div class="col"> 
                    <router-link to="/overdraft-summary/add" class="btn btn-primary float-right margin-0 pd-10">
                        <span><i class="fas fa-plus-circle"></i></span>
                        Add
                    </router-link>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-body p-3">
                <TableFilter f_action='payin' :f_loading=loading :f_submit="searchSubmit" :f_download=true
                 :f_partner_id=true :f_overdraft_status=true :f_overdraft_type=true
                  @toggle-expand="toggleExpandListener" :expand="expand" :balanceData="balanceData" ></TableFilter>
              </div>
              <div class="card-body p-3">
                <div class="row mt-4">
                  <a :href="filedownload" v-if="filedownload" class="btn btn-behance mr-2" type="button"><i class="fa fa-solid fa-file-excel"></i> Dowload</a>
                  <!-- show-index -->
                  <EasyDataTable
                      buttons-pagination
                      alternating
                      v-model:server-options="serverOptions"
                      :server-items-length="numberOfPages"
                      :loading="loading"
                      :headers="headers"
                      :items="transactions"
                      :search-field="searchField"
                      :search-value="searchValue"
                  >
                    <template #item-trx_status="item">
                      <span v-if="item.trx_status=='00'" class="badge bg-success text-white text-bold">{{item.trx_status}}</span>
                      <span v-else-if="item.trx_status=='96'" class="badge bg-error text-white text-bold">{{item.trx_status}}</span>
                      <span v-else class="badge bg-warning text-white text-bold">{{item.trx_status}}</span>
                    </template>
                    <template #item-netAmount="{netAmount}">
                      <span class="text-bold">{{netAmount}}</span>
                    </template>
                    <template #item-status_desc="{status_desc}">
                      <div class="bg-warning text-center" v-if="status_desc=='ACTIVE'">
                        <span class="badge badge-warning">{{status_desc}}</span>
                      </div>
                      <div class="bg-success text-center" v-else-if="status_desc=='PAID'">
                        <span class="badge badge-success">{{status_desc}}</span>
                      </div>
                      <div class="bg-info text-center" v-else>
                        <span class="badge badge-info">{{status_desc}}</span>
                      </div>
                    </template>
                    <template #item-view="item">
                      <button  class="btn btn-primary my-3" @click="viewDetail(item)">
                          See Detail
                      </button>
                    </template>
                    <template #loading>
                      <img :src="imageLoading" style="width: 100px; height: 80px;"/>
                    </template>
                  </EasyDataTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import RequestService from "@/services/request.service";
import TableFilter from "@/views/TableFilter";
import e2payApis from "@/services/e2pay-apis";
import ModalOverdraftDetail from "@/views/overdraft-summary/ModalOverdraftDetail";

export default {
  name: "TableOverdraftSummary",
  components:{
    TableFilter,
    ModalOverdraftDetail,
  },
  computed:{
    currentRouteName() {
      return this.$route.name;
    }
  },
  methods:{
    toggleExpandListener() {
      this.expand = !this.expand;
    },
    searchSubmit(dataParam){
      this.searchParam = dataParam;
      this.searchParam.trxType = 'payin';
      this.searchParam.serverOptions = this.serverOptions;

      this.fetchData();
    },
    fetchData(){
      this.loading = true;
      this.searchParam.trxType = 'payin';
      this.searchParam.serverOptions = this.serverOptions;
      RequestService.postRequest(e2payApis.GET_OVERDRAFT_LIST, this.searchParam).then(
          (response)=>{
            this.transactions = response.data.data.data;
            this.numberOfPages = response.data.data.dataCount;
            this.loading = false;
          }
      );
    },
    viewDetail(form) {
      this.selectedForm = form;
      this.overdraftDialog = true;
    },
    closeDialog() {
      this.overdraftDialog = false;
    }
  },
  data:()=>({
    imageLoading: require('@/assets/img/cube_loading.gif'),
    balanceData: [],
    filedownload:'',
    transactions: [],
    content:"",
    numberOfPages : 0,
    searchField:'refNo',
    searchValue:'',
    loading: false,
    overdraftDialog: false,
    selectedForm: {},
    dialogTitle: "",
    settle: "0",
    receivables: "0",
    headers: [
      { text: "No", value: "no" , sortable: true},
      { text: "Trx ID", value: "trx_id" , sortable: true},
      { text: "Requested Datetime", value: "requested_at" , sortable: false},
      { text: "Requested By", value: "requested_user" , sortable: true},
      { text: "Merchant", value: "partner_name" , sortable: true},
      { text: "Type", value: "overdraft_type_desc" , sortable: true},
      { text: "Amount", value: "amount_in_string" , sortable: true},
      { text: "Status", value: "status_desc" , sortable: true},
      { text: "See Detail", value: "view" , sortable: false},
    ],
    searchParam:{},
    serverOptions:{
      page: 1,
      rowsPerPage: 25,
      sortBy:'',
      sortType:'',
    },
    expand: false,
    chartData:{
      labels: [ "2023-08-01", "2023-08-09", "2023-08-10", "2023-08-19", "2023-08-21" ],
      datasets: [
        {
          label: "Payout",
          backgroundColor: "#a3f879",
          borderColor: "#a3f879",
          data: [ 0, 1, 3, 0, 0 ],
          tension: 0.1
        }
      ]
    },
    tooltipMessage: " This amount will be transferred automatically to payout balance (time exactly for auto transfer ?)" +
     "except weekend and public holiday. If you wish to stop it or wants to split please click link on label Ready to Settle or" +
     "go to Settlement page",
  }),
  beforeMount() {
    this.fetchData();
  },
  watch:{
    serverOptions:{
      handler(){
        this.searchParam.serverOptions = this.serverOptions;
        this.fetchData(this.searchParam);
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>