<template>
  <DashboardLeftMenu></DashboardLeftMenu>
  <PageFeeCalculator></PageFeeCalculator>
</template>

<script>
import DashboardLeftMenu from "@/components/DashboardLeftMenu";
import PageFeeCalculator from "@/views/PageFeeCalculator";
export default {
  name: "RouteFeeCalculator",
  components:{
    DashboardLeftMenu,
    PageFeeCalculator
  }
}
</script>

<style scoped>

</style>