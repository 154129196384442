<template>
  <DashboardLeftMenu></DashboardLeftMenu>
  <TablePayoutLog></TablePayoutLog>
</template>

<script>
import DashboardLeftMenu from "@/components/DashboardLeftMenu";
import TablePayoutLog from "@/views/TablePayoutLog";
export default {
  name: "RoutePayoutLog",
  components:{
    DashboardLeftMenu,
    TablePayoutLog
  }
}
</script>

<style scoped>

</style>