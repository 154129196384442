<template>
    <DashboardLeftMenu></DashboardLeftMenu>
    <TableFailedTransaction></TableFailedTransaction>
  </template>
  
  <script>
  import DashboardLeftMenu from "@/components/DashboardLeftMenu";
  import TableFailedTransaction from "@/views/failed-payin/List";
  
  export default {
    name: "RouterPageTransaction",
    components:{
        TableFailedTransaction,
      DashboardLeftMenu,
    }
  }
  </script>
  
  <style scoped>
  
  </style>