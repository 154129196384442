<template>
  <DashboardLeftMenu></DashboardLeftMenu>
  <ServerWhitelist></ServerWhitelist>
</template>

<script>
import DashboardLeftMenu from "@/components/DashboardLeftMenu";
import ServerWhitelist from "@/views/ServerWhitelist";
export default {
  name: "RouteWhitelist",
  components:{
    DashboardLeftMenu,
    ServerWhitelist
  }
}
</script>

<style scoped>

</style>