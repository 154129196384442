<template>
     <div class="card card-frame">
        <div class="card-header">
            <button class="btn btn-icon btn-3 btn-outline-primary mt-2" @click="$router.go(-1)">
                <span class="btn-inner--icon"><i class="fa fa-arrow-left"></i></span>
            </button>
            Overdraft Summary
        </div>
        <div class="card-body"> 
            <div class="row" v-if="isError"> 
                <div class="col"> 
                <div class="alert alert-danger alert-dismissible fade show" role="alert">
                    <span class="alert-text text-white"><strong>Error!</strong> {{errorMsg}}</span>
                    <button class="btn-close" data-bs-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                </div>
            </div>
            <form>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                      <label class="form-control-label">Merchant</label>
                      <VueMultiselect
                          :multiple="false"
                          v-model="form.partner_id"
                          :options="merchantOptions"
                          label="name"
                          track-by="code"
                          placeholder="Pick Merchant"
                      >
                      </VueMultiselect>
                      <span class="invalid-feedback" v-if="v$.form.partner_id.$error">{{ v$.form.partner_id.$errors[0].$message }}</span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                      <label class="form-control-label">Type</label>
                      <VueMultiselect
                          :multiple="false"
                          v-model="form.overdraft_type"
                          :options="typeOptions"
                          label="name"
                          track-by="id"
                          placeholder="Pick Type"
                      >
                      </VueMultiselect>
                      <span class="invalid-feedback" v-if="v$.form.partner_id.$error">{{ v$.form.partner_id.$errors[0].$message }}</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                      <label class="form-control-label">Amount</label>
                      <input type="number" class="form-control" :class="{'is-invalid' : v$.form.amount.$error}" v-model="form.amount" placeholder="Menu" aria-label="Menu" aria-describedby="menu">
                      <span class="invalid-feedback" v-if="v$.form.amount.$error">{{ v$.form.amount.$errors[0].$message }}</span>
                  </div>
                </div>
              </div>
            </form>
            <div class="row mt-2">
                <div class="col-12 col-md-12"> 
                    <button @click="$router.go(-1)" class="btn btn-danger mr-2" :disabled="loading" >Cancel</button>
                    <button @click="submit()" class="btn btn-success mr-2" :disabled="loading" >Submit</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import useValidate from '@vuelidate/core'
import {numeric, required} from '@vuelidate/validators'
import RequestService from "@/services/request.service";
import e2payApis from "@/services/e2pay-apis";
import '@vuepic/vue-datepicker/dist/main.css';

export default {
  name: "OverdraftSummaryForm",
  props:{
    // paramModel: ,
    // collapsed: Boolean,
  },
  components: {
  },
  mounted() {
    this.fetchData();
  },
  methods:{
    fetchData(){
      RequestService.postRequest(e2payApis.GET_LINKED_PARTNER, {}).then(
        (response)=>{
          if(response.data.code==1){
            this.merchantOptions = response.data.data;
            this.loading = false;
          }else{
            this.loading = false;
            this.isError = true;
            this.errorMsg = response.data.message;
          }
        }
      )
      if (this.$route.params.id !== undefined) {
        this.loading = true;
        RequestService.postRequest(e2payApis.GET_INFORMATION, {id: this.$route.params.id}).then(
            (response)=>{
              let items = response.data.data.messages;
              if (items.length > 0) {
                let item = items[0];
                this.form.id = item.id;
                this.form.start_date = item.start_date;
                this.form.end_date = item.end_date;
                this.form.message_text = item.message_text;
                this.form.amount = item.amount;
              }
            }
        );
      }
    },
    submit() {
      this.v$.form.$validate();
      if(!this.v$.form.$error){
        let tempClassInfo = this.form.partner_id;
        this.form.partner_id = this.form.partner_id.code;
        this.form.overdraft_type = this.form.overdraft_type.id;
        RequestService.postRequest(this.$route.params.id !== undefined ? e2payApis.PUT_INFORMATION: 
          e2payApis.ADD_OVERDRAFT, this.form).then(
            (response)=>{
              this.loading = false;
              if(response.data.code==1){
                this.$router.go(-1);
              }else{
                this.isError = true;
                this.errorMsg = response.data.message;
                this.form.partner_id = tempClassInfo;
              }
            }
        )
      }
    }
  },
  data:()=>({
    v$: useValidate(),
    loading: false,
    partnerInfoIcon: 'fa-solid fa-chevron-down',
    partner_info_collapsed: false,
    registerMessage:'',
    isDisabledCryptoModal: true,
    isError: false,
    errorMsg: '',
    status: '',
    merchantOptions: [],
    typeOptions: [
      {
        "id":1,
        "name":"Daily"
      },
      {
        "id":2,
        "name":"Public Holiday"
      },
    ],
    form: {
      id: '',
      partner_id: '',
      overdraft_type: '',
      amount: '',
    },
  }),
  validations() {
    return {
      form: {
        amount:{required,numeric},
        partner_id:{required},
        overdraft_type:{required}
      }
    }
  },
}


</script>