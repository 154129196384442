<template>
  <DashboardLeftMenu></DashboardLeftMenu>
  <TablePaymengtGateway></TablePaymengtGateway>
</template>

<script>
import DashboardLeftMenu from "@/components/DashboardLeftMenu";
import TablePaymengtGateway from "@/views/TablePaymengtGateway";
export default {
  name: "RoutePageSetting",
  components:{
    DashboardLeftMenu,
    TablePaymengtGateway
  }
}
</script>

<style scoped>

</style>