<template>
    <div class="card card-frame">
       <div class="card-header">
           <button class="btn btn-icon btn-3 btn-outline-primary mt-2" @click="$router.go(-1)">
               <span class="btn-inner--icon"><i class="fa fa-arrow-left"></i></span>
           </button>
           Partner 
       </div>
       <div class="card-body"> 
           <div class="row" v-if="isError"> 
               <div class="col"> 
               <div class="alert alert-danger alert-dismissible fade show" role="alert">
                   <span class="alert-text text-white"><strong>Error!</strong> {{errorMsg}}</span>
                   <button class="btn-close" data-bs-dismiss="alert" aria-label="Close">
                       <span aria-hidden="true">&times;</span>
                   </button>
               </div>
               </div>
           </div>
           <form>
               <div class="row">
                   <div class="col-md-6">
                       <div class="form-group">
                           <label class="form-control-label">Partner Name</label>
                           <input type="text" class="form-control" v-model="formData.form_p_name" placeholder="Partner Name">
                       </div>
                   </div>
                   <div class="col-md-6">
                       <div class="form-group">
                           <label class="form-control-label">Partner Address</label>
                           <input type="text" class="form-control" v-model="formData.form_p_address" placeholder="Partner Address">
                       </div>
                   </div>
               </div>
               <div class="row">
                   <div class="col-md-6">
                       <div class="form-group">
                           <label class="form-control-label">Email Address</label>
                           <input type="email" class="form-control" v-model="formData.form_p_email" placeholder="Email Address">
                       </div>
                   </div>
                   <div class="col-md-6">
                       <div class="form-group">
                           <label class="form-control-label">Telephone</label>
                           <input type="text" class="form-control" v-model="formData.form_p_phone" placeholder="Telephone">
                       </div>
                   </div>
               </div>
               <div class="row">
                   <div class="col-md-6">
                       <div class="form-group">
                           <label class="form-control-label">VA Pooling</label>
                           <VueMultiselect
                               :multiple="false"
                               v-model="formData.form_p_vapooling"
                               :options="vapoolOptions"
                               label="name"
                               track-by="code"
                               placeholder="Pick Pool"
                           >
                           </VueMultiselect>
                       </div>
                   </div>
                   <div class="col-md-6">
                       <div class="form-group">
                           <label class="form-control-label">Linked Channel</label>
                           <VueMultiselect
                               :multiple="true"
                               v-model="formData.form_p_channel"
                               :options="channelOptions"
                               label="name"
                               track-by="code"
                               placeholder="Pick Channel"
                           >
                           </VueMultiselect>
                       </div>
                   </div>
                 </div>
                 <div class="row">
                   <div class="col-md-6">
                       <div class="form-group">
                           <label class="form-control-label">General IP Address</label>
                           <vue-tags-input
                                v-model="ip"
                                :tags="ips"
                                @tags-changed="newTags => ips = newTags"
                                />
                       </div>
                   </div>
                   <div class="col-md-6">
                       <div class="form-group">
                           <label class="form-control-label">Whitelist IP Address</label>
                           <vue-tags-input
                                v-model="wl_ip"
                                :tags="wl_ips"
                                @tags-changed="newTags => wl_ips = newTags"
                                />
                       </div>
                   </div>
               </div>
                 <div class="row">
                   <div class="col-md-6">
                     <div class="form-group">
                         <label class="form-control-label">Is Active</label>
                         <div class="form-check form-switch">
                           <input class="form-check-input" :class="formData.form_p_active == 1? 'bg-success' : ''" type="checkbox" v-model="formData.form_p_active" 
                             true-value="1" false-value="0">
                         </div>
                     </div>
                   </div>
                   <div class="col-md-6">
                     <div class="form-group">
                         <label class="form-control-label">Allow Overdraft</label>
                         <div class="form-check form-switch">
                           <input class="form-check-input" :class="formData.form_p_allow_overdraft == 1? 'bg-success' : ''" type="checkbox" v-model="formData.form_p_allow_overdraft" 
                             true-value="1" false-value="0">
                         </div>
                     </div>
                   </div>
                 </div>
           </form>
           <div class="row mt-2" v-if="status != 'A'">
               <div class="col-12 col-md-12"> 
                   <button @click="submit()" class="btn btn-success mr-2" :disabled="loading_partner" >Submit</button>
               </div>
           </div>
       </div>
   </div>
</template>
<script>
import VueMultiselect from 'vue-multiselect'
import useValidate from '@vuelidate/core'
import {required,numeric} from '@vuelidate/validators'
import RequestService from "@/services/request.service";
import e2payApis from "@/services/e2pay-apis";
import VueTagsInput from "@sipec/vue3-tags-input";

export default {
 name: "PartnerForm",
 props:{
   // paramModel: ,
   // collapsed: Boolean,
 },
 components: {
   VueMultiselect,
   VueTagsInput
 },
 mounted() {
   this.fetchData();
 },
 methods:{
   fetchData(){
       RequestService.postRequest(e2payApis.GET_PAYMENTLIST, {}).then(
         (response)=>{
           if(response.data.code==1){
             this.channelOptions = response.data.data;
             this.loading = false;
           }else{
             this.loading = false;
             this.isError = true;
             this.errorMsg = response.data.message;
           }
         }
       )
       RequestService.postRequest(e2payApis.GET_VA_POOLING_LIST, {}).then(
         (response)=>{
           if(response.data.code==1){
             this.vapoolOptions = response.data.data;
             this.loading = false;
           }else{
             this.loading = false;
             this.isError = true;
             this.errorMsg = response.data.message;
           }
         }
       )
       if (this.$route.params.id !== undefined) {
          this.loading = true;
          RequestService.postRequest(e2payApis.GET_PARTNER, {filter_partner_code: this.$route.params.id}).then(
              (response)=>{
                let items = response.data.data.data;
                if (items.length > 0) {
                    let item = items[0];
                    this.formData.form_p_id = item.partner_id;
                    this.formData.form_p_name = item.partner_name;
                    this.formData.form_p_address = item.partner_address;
                    this.formData.form_p_email = item.partner_email;
                    this.formData.form_p_phone = item.partner_telp;
                    this.formData.form_p_vapooling = item.partner_config;
                    item.partner_allow_ip_id.forEach(ip => {
                        this.ips.push({
                            tiClasses:["ti-valid"],
                            text: ip.code
                        });
                        this.formData.form_p_ip.push({name: ip.code});
                    });
                    item.partner_whitelist_allow_ip_id.forEach(ip => {
                        this.wl_ips.push({
                            tiClasses:["ti-valid"],
                            text: ip.code
                        });
                        this.formData.form_p_ip_whitelist.push({name: ip.code});
                    });
                    this.formData.form_p_channel = item.partner_allow_channel_id;
                    this.formData.form_p_active = item.partner_status;
                    console.log('statu', this.formData.form_p_active);
                    this.formData.form_p_allow_overdraft = item.allow_overdraft;
                }
                this.loading = true;
              }
          );
        }
       
   },
   submit() {
       let ipsTemp = [];
       this.ips.forEach(ip => {
        ipsTemp.push({name: ip.text})
       });
       let wlIpsTemp = [];
       this.wl_ips.forEach(ip => {
        wlIpsTemp.push({name: ip.text})
       });
       this.formData.form_p_ip = ipsTemp;
       this.formData.form_p_ip_whitelist = wlIpsTemp;
       RequestService.postRequest(this.$route.params.id !== undefined ? e2payApis.POST_EDIT_PARTNER : 
        e2payApis.POST_ADD_PARTNER, this.formData).then(
         (response)=>{
           if(response.data.code==1){
             this.loading = false;
             this.$router.go(-1);
           }else{
             this.loading = false;
             this.isError = true;
             this.errorMsg = response.data.message;
           }
         }
       )
   }
 },
 data:()=>({
   v$: useValidate(),
   loading_partner: false,
   partnerInfoIcon: 'fa-solid fa-chevron-down',
   partner_info_collapsed: false,
   registerMessage:'',
   isDisabledCryptoModal: true,
   isError: false,
   errorMsg: '',
   status: '',
   channelOptions: [{code:25,name:'Dana Wallet'},{code:26,name:'Bank Mega'}],
   vapoolOptions: [{code:0,name:'Test'}],
   ip: "",
   wl_ip: "",
   ips: [],
   wl_ips: [],
   formData: {
     form_p_id: '',
     form_p_name: '',
     form_p_address: '',
     form_p_email: '',
     form_p_phone: '',
     form_p_vapooling: {},
     form_p_ip: [],
     form_p_ip_whitelist: [],
     form_p_channel: [],
     form_p_active: 1,
     form_p_allow_overdraft: 0,
   },
 }),
 validations() {
   return {
     formData: {
       form_p_id:{required,numeric},
       // form_p_disbusrment_approved:{required,numeric},
       // form_p_local_bank_approved:{required,numeric},
       // form_p_crypto_approved:{required,numeric},
       // form_p_overdraft_approved:{required,numeric},
       // form_p_description:{minLength:minLength(5)},
     }
   }
 },
}


</script>