<template>
  <main class="main-content">
    <NavigationTop :pages=currentRouteName :subpages=currentRouteName></NavigationTop>
    <div class="container-fluid py-4">
      <ProductPricePage></ProductPricePage>
      <div class="row">
        <div class="col-12">
          <div class="card mb-12">
            <div class="card-header pb-0">
              <h6>Data {{currentRouteName}}</h6>
            </div>
            <div class="card">
              <div class="card-body p-3">
                <TableFilter :f_submit="searchSubmit" :f_loading=loading :f_paymentid=true :f_name=true :f_partner_code=true :f_partner_name=true></TableFilter>
              </div>
              <div class="card-body p-3">
                <span class="text-black text-sm-start mb-3">
                  * This is mapping data between toppay product to backend PG product, please be carefull when creating/editing
                </span>
                <EasyDataTable
                    show-index
                    buttons-pagination
                    alternating
                    v-model:server-options="serverOptions"
                    :server-items-length="numberOfPages"
                    :loading="loading"
                    :headers="headers"
                    :items="transactions"
                    :search-field="searchField"
                    :search-value="searchValue"
                >
                  <template #item-is_active="{is_active}">
                    <div class="bg-success text-center" v-if="is_active==1">
                      <span class="badge badge-success">Active</span>
                    </div>
                    <div class="bg-gray-600 text-center" v-else>
                      <span class="badge badge-warning">Not Active</span>
                    </div>
                  </template>
                  <template #loading>
                    <img :src="imageLoading" style="width: 100px; height: 80px;"/>
                  </template>
                </EasyDataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import DashboardService from "@/services/dashboard.service";
import NavigationTop from "@/components/NavigationTop";
import TableFilter from "@/views/TableFilter";
export default {
  name: "TableProductMapping",
  components: {
    NavigationTop,
    TableFilter
  },
  computed:{
    currentRouteName() {
      return this.$route.name;
    }
  },
  methods:{
    searchSubmit(dataParam){
      this.searchParam = dataParam;
      this.searchParam.trxType = 'payin';
      this.searchParam.serverOptions = this.serverOptions;
      this.fetchData();
    },
    fetchData(){
      this.loading = true;
      this.searchParam.trxType = 'payin';
      this.searchParam.serverOptions = this.serverOptions;
      DashboardService.getProductMappingListData(this.searchParam).then(
          (response)=>{
            this.transactions = response.data.data.data;
            this.numberOfPages = response.data.data.dataCount;
            this.loading = false;
          }
      );
    }
  },
  data:()=>({
    imageLoading: require('@/assets/img/cube_loading.gif'),
    transactions: [],
    content:"",
    numberOfPages : 0,
    searchField:'refNo',
    searchValue:'',
    loading: false,
    headers: [
      { text: "Payment Id", value: "payment_id" , sortable: true},
      { text: "Product Name", value: "product_name" , sortable: true},
      { text: "Category", value: "category" , sortable: true},
      { text: "PG Id", value: "pg_id" , sortable: true},
      { text: "PG Name", value: "pg_name" , sortable: true},
      { text: "PG Desc", value: "pg_account" , sortable: true},
      { text: "Config", value: "pg_config" , sortable: true},
    ],
    searchParam:{},
    serverOptions:{
      page: 1,
      rowsPerPage: 25,
      sortBy:'',
      sortType:'',
    }
  }),
  mounted() {
    this.fetchData();
  },
  watch:{
    serverOptions:{
      handler(){
        this.searchParam.serverOptions = this.serverOptions;
        this.fetchData(this.searchParam);
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>